"use strict";

import { CountUp } from "countup.js";

var oldScroll = 0;
$(window).on("scroll", function() {
    var $header = $("#header");
    var $body = $("body");
    var headerHeight = $header.innerHeight();
    var scrollTop = $(window).scrollTop();
    var $secondOptionForm = $(".second-option-quick-form");

    if (scrollTop >= 1) {
        $header.addClass("stickyHeader");
        $body.css("padding-top", headerHeight + "px");
    } else {
        $header.removeClass("stickyHeader");
        $body.css("padding-top", "0px");
    }

    function handleHeaderUpDownAnimation() {
        if ($secondOptionForm.hasClass("show")) return;

        if (oldScroll < scrollTop) {
            $header.addClass("is-up");
        } else {
            $header.removeClass("is-up");
        }

        oldScroll = scrollTop <= 0 ? 0 : scrollTop;
    }

    handleHeaderUpDownAnimation();
});
includeHTML();

function handleTopBarHeader() {
    var $target = $("#top-bar");
    if (!$target.is(":hidden")) {
        $("#header").addClass("is-topbar");
    }
}

function toggleSearchBar() {
    var $search = $("#search-sm");
    $("#search-sm-trigger").on("click", function() {
        $search.fadeToggle(100).focus();
    });
    $search.on("blur", function() {
        $search.fadeOut(100);
    });
}

function initLocationCarousel() {
    try {
        var $carouselTarget = $(".network-section-carousel-carousel");
        var $carouselTabs = $(".network-section-carousel-tabs a");
        var carousel;
        var carouselOptions = {
            slidesToShow: 4,
            infinite: true,
            prevArrow: ".network-section-carousel-prev",
            nextArrow: ".network-section-carousel-next",
            responsive: [{
                    breakpoint: 1199,
                    settings: {
                        slidesToShow: 3,
                        arrows: false
                    }
                },
                {
                    breakpoint: 1023,
                    settings: {
                        slidesToShow: 4,
                        arrows: false
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 2.1,
                        arrows: false
                    }
                },
                {
                    breakpoint: 413,
                    settings: {
                        slidesToShow: 2.1,
                        arrows: false
                    }
                }
            ]
        };
        if ($carouselTarget.length)
            carousel = $carouselTarget.slick(carouselOptions);

        function handleClick(e) {
            var sliderIndex = $(this).attr("data-slider-index");
            $carouselTarget.slick("slickGoTo", parseInt(sliderIndex));
        }

        $carouselTabs.on("click", handleClick);
    } catch (error) {
        console.error(error);
    }
}

function toggleGridLayout() {
    var listClass = "fa-list-ul";
    var gridClass = "fa-th-large";
    var col12 = "col-12";
    var col6 = "col-6";
    $("[data-grid-trigger]").on("click", function(e) {
        var $target = $("[data-grid-item]");
        var horizontalClass = $target.attr("data-grid-horizontal-class");
        var targetChildElm = $target.attr("data-grid-target-class");
        var $this = $(e.currentTarget).find("i");
        var $cardItems =
            targetChildElm === "current" ?
            $target :
            $target.find(targetChildElm);

        if ($target.hasClass(col6)) {
            $target.removeClass(col6).addClass(col12);
            $this.removeClass(listClass).addClass(gridClass);
            $cardItems.addClass(horizontalClass);
        } else {
            $target.removeClass(col12).addClass(col6);
            $this.removeClass(gridClass).addClass(listClass);
            $cardItems.removeClass(horizontalClass);
        }
    });
}


function initDoctorCarouselIntHome() {
    if (!$.fn.slick) return;
    var $target = $(".meet-doctor-section-carousel-int-home");
    var slideCount = $target.data("slide-count");
    var rowsCount = slideCount < 7 ? 1 : 2;

    $(".meet-doctor-section-carousel-int-home").slick({
        slidesToShow: 4,
        slidesToScroll: 4,
        infinite: false,
        dots: true,
        arrows:false,
        rows: rowsCount,
        responsive: [{
                breakpoint: 1199,
                settings: {
                    slidesToShow: 2.8,
                    rows: rowsCount,
                    slidesToScroll: 2,
                }
            },
            {
                breakpoint: 1023,
                settings: {
                    slidesToShow: 4.2,
                    arrows: false,
                    rows: 1,
                    slidesToScroll: 2,
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 2.3,
                    dots: false,
                    arrows: false,
                    rows: 1,
                    slidesToScroll: 2,
                }
            },
            {
                breakpoint: 413,
                settings: {
                    slidesToShow: 2.1,
                    dots: false,
                    arrows: false,
                    rows: 1,
                    slidesToScroll: 2,
                }
            }
        ]
    });
}




function technologyCarousel() {
    if (!$.fn.slick) return;
    $(".technology-carousel").slick({
        slidesToShow: 3,
        infinite: false,
        arrows: false, // This hides the prev and next buttons
        responsive: [{
                breakpoint: 1199,
                settings: {
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 1023,
                settings: {
                    slidesToShow: 2.1,
                    arrows: false
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 2.2,
                    arrows: false
                }
            }
        ]
    });
}



function initDoctorCarousel() {
    if (!$.fn.slick) return;
    var $target = $(".meet-doctor-section-carousel");
    var slideCount = $target.data("slide-count");
    var rowsCount = slideCount < 7 ? 1 : 2;

    $(".meet-doctor-section-carousel").slick({
        slidesToShow: 3.91,
        infinite: false,
        nextArrow: ".external-carousel-btn-next",
        prevArrow: ".external-carousel-btn-prev",
        rows: rowsCount,
        responsive: [{
                breakpoint: 1199,
                settings: {
                    slidesToShow: 2.8,
                    rows: rowsCount
                }
            },
            {
                breakpoint: 1023,
                settings: {
                    slidesToShow: 4.2,
                    arrows: false,
                    rows: 1
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 2.3,
                    arrows: false,
                    rows: 1
                }
            },
            {
                breakpoint: 413,
                settings: {
                    slidesToShow: 2.1,
                    arrows: false,
                    rows: 1
                }
            }
        ]
    });
}



function initSuccessStories() {
    if (!$.fn.slick) return;

    $(".student-success-stories").slick({
        arrows: true,
        dots: false,
        draggable: true,
        easing: true,
        swipe: true,
        useTransform: true,
        zIndex: 0,
        infinite: false,
        slidesToShow: 2,
        slidesToScroll: 1,
        prevArrow: ".network-section-carousel-prev",
        nextArrow: ".network-section-carousel-next",
        responsive: [{
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1.1,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1.1,
                    slidesToScroll: 1,
                },
            },
        ],
    });


}

function initTechnologyCarousel() {
    if (!$.fn.slick) return;
    $(".technology-section-carousel").slick({
        slidesToShow: 1.83,
        infinite: false,
        responsive: [{
                breakpoint: 1023,
                settings: {
                    slidesToShow: 2.1,
                    arrows: false
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1.1,
                    arrows: false
                }
            },
            {
                breakpoint: 413,
                settings: {
                    slidesToShow: 1.1,
                    arrows: false
                }
            }
        ]
    });
}


function countryAwardsCarousel(){
    if (!$.fn.slick) return;
    $(".awards-carousel").slick({
        slidesToShow: 4,
        infinite: false,
        arrows: false,
        responsive: [{
                breakpoint: 1199,
                settings: {
                    slidesToShow: 4,
                    arrows: false
                }
            },
            {
                breakpoint: 1023,
                settings: {
                    slidesToShow: 2.2,
                    arrows: false
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 2.2,
                    arrows: false
                }
            }
        ]
    });
}


function initAmenitiesCarousel() {
    if (!$.fn.slick) return;
    $(".section-amenities-carousel").slick({
        slidesToShow: 4.5,
        variableWidth: true,
        infinite: false
    });
}


function initIntCenterCarousel() {
    if (!$.fn.slick) return;
    $('.section-int-center-carousel').slick({
        slidesToShow: 3,
        infinite: false,
        responsive: [
         {
            breakpoint: 1024,
            settings: {
             slidesToShow: 3,
             slidesToScroll: 3,

            }
         },
         {
            breakpoint: 600,
            settings: {
             slidesToShow: 2.2,
             slidesToScroll: 2
            }
         },
         {
            breakpoint: 480,
            settings: {
             slidesToShow: 1.2,
             slidesToScroll: 1
            }
         }
        ]
    });
}


function investorReportCarousel() {
    if (!$.fn.slick) return;
    $('.investor-report-carousel').slick({
        slidesToShow: 3,
        infinite: false,
        responsive: [
            {
            breakpoint: 600,
            settings: {
                slidesToShow: 2.2,
                slidesToScroll: 2,
                arrows: false
            }
            },
            {
            breakpoint: 480,
            settings: {
                slidesToShow: 1.2,
                slidesToScroll: 1,
                arrows: false
            }
            }
        ],
        prevArrow: false,
        nextArrow: false
    });
}



function initInstituteListCarousel() {
    if (!$.fn.slick) return;
    $(".institute-list-carousel").slick({
        slidesToShow: 3.65,
        infinite: false,
        responsive: [{
                breakpoint: 1199,
                settings: {
                    slidesToShow: 2.8
                }
            },
            {
                breakpoint: 1023,
                settings: {
                    slidesToShow: 4.2,
                    arrows: false
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1.2,
                    arrows: false
                }
            }
        ]
    });
}









function initBlogCarousel() {
    if (!$.fn.slick) return;
    $(".blog-carousel").slick({
        dots: true,
        prevArrow: ".blog-carousel-trigger-prev",
        nextArrow: ".blog-carousel-trigger-next"
    });
}

function initVideoCarousel() {
    if (!$.fn.slick) return;
    $(".sidebar-video-carousel").slick({
        dots: true,
        arrows: false,
        infinite: false
    });
}

function initDoctorImgCarousel() {
    if (!$.fn.slick) return;
    $(".doctor-img-carousel").slick({
        dots: true,
        arrows: false,
        infinite: false,
        responsive: [{
            breakpoint: 767,
            settings: {
                slidesToShow: 1.1
            }
        }]
    });
}

function initDateCalendar() {
    var active_dates1 = ["17/10/2020"];
    var active_dates2 = ["18/10/2020"];
    var active_dates3 = ["19/10/2020"];
    var active_dates4 = ["20/10/2020"];
    var active_dates5 = ["22/10/2020"];
    var active_dates6 = ["24/10/2020"];
    var active_dates7 = ["26/10/2020"];
    if (!$.fn.datepicker) return;
    $(".date-calendar").datepicker({
        todayHighlight: false,
        beforeShowDay: function beforeShowDay(date) {
            var d = date;
            var curr_date = d.getDate();
            var curr_month = d.getMonth() + 1; //Months are zero based

            var curr_year = d.getFullYear();
            var formattedDate = curr_date + "/" + curr_month + "/" + curr_year;

            if ($.inArray(formattedDate, active_dates1) != -1) {
                return {
                    classes: "color-blue"
                };
            }

            if ($.inArray(formattedDate, active_dates2) != -1) {
                return {
                    classes: "color-green"
                };
            }

            if ($.inArray(formattedDate, active_dates3) != -1) {
                return {
                    classes: "color-green-dark"
                };
            }

            if ($.inArray(formattedDate, active_dates4) != -1) {
                return {
                    classes: "color-green-lighten"
                };
            }

            if ($.inArray(formattedDate, active_dates5) != -1) {
                return {
                    classes: "color-multi-2"
                };
            }

            if ($.inArray(formattedDate, active_dates6) != -1) {
                return {
                    classes: "color-multi-3"
                };
            }

            if ($.inArray(formattedDate, active_dates7) != -1) {
                return {
                    classes: "color-multi-4"
                };
            }

            return;
        }
    });
}

function initSingleStoryCarousel() {
    if (!$.fn.slick) return;
    $(".single-story-carousel").slick();
}

function initSelectBox() {
    if (!$.fn.select2) return;
    $("[data-select-box]").select2();
}

function mobileNavigationTrigger() {
    var baseClass = "d-block f-30";
    var closeClass = "fas fa-times";
    var menuClass = "fas fa-bars";

    function handleClick(e) {
        e.preventDefault();
        var $target = $(".navigation-fullpage-mobile");
        var $trigger = $(e.currentTarget);

        if ($target.is(":hidden")) {
            $target.show();
            $trigger.find("i").attr("class", baseClass + " " + closeClass);
            $("body").addClass("modal-open");
        } else {
            $target.hide();
            $trigger.find("i").attr("class", baseClass + " " + menuClass);
            $("body").removeClass("modal-open");
        }
    }

    $("body").on("click", ".navigation-fullpage-trigger", handleClick);
}

function classToggler() {
    var activeClass = "active";
    $("body").on("click", "*[data-toggle-class-trigger]", function() {
        var $target = $($(this).attr("data-toggle-class-target"));
        var $trigger = $(this);
        var triggerActiveClass = $trigger.attr("data-toggle-trigger-class");
        var targetToggleCalss = $(this).attr("data-toggle-class");
        var isFullPage = $trigger.get(0).hasAttribute("data-is-full-page");

        var customEventData = {
            target: $target,
            trigger: $(this)
        };

        if ($target.hasClass(targetToggleCalss)) {
            $(document).trigger("mx.class.remove", customEventData);
            $target.removeClass(targetToggleCalss);
            $trigger.removeClass(triggerActiveClass || activeClass);
            $("body").removeClass("modal-open");
        } else {
            $(document).trigger("mx.class.add", customEventData);
            $target.addClass(targetToggleCalss);
            $trigger.addClass(triggerActiveClass || activeClass);
            isFullPage && $("body").addClass("modal-open");
            $(".navigation-fullpage-mobile-body").scrollTop(0);
        }
    });
}

function handleSearchOverlayMobile() {
    $(document).on("mx.class.add", function(_e, data) {
        var $target = $(data.target);
        var $input = $target.find("input");
        setTimeout(function() {
            $input.trigger("focus");
        });
    });
}

function handleMegaDropdown() {
    $(document).on("click", ".megadropdown-item-solid .dropdown-menu", function(
        e
    ) {
        e.stopPropagation();
    });
}

function initAutoSuggest() {
    if (!$.fn.typeahead) return;
    var rootUrl = "https://maxapp.in/meta/";
    const doctorsData = new Bloodhound({
        datumTokenizer: function(datum) {
            return Bloodhound.tokenizers.whitespace(datum.match);
        },
        queryTokenizer: Bloodhound.tokenizers.whitespace,
        remote: {
            url: rootUrl + "doctor-search#%QUERY",
            wildcard: "%QUERY",
            transport: function(opts, onSuccess, onError) {
                var url = opts.url.split("#")[0];
                var query = opts.url.split("#")[1];
                $.ajax({
                    url: url + "/" + query,
                    type: "GET",
                    success: onSuccess,
                    error: onError
                });
            }
        }
    });

    const getAdapter = function(url) {
        var adapter = new Bloodhound({
            datumTokenizer: function(datum) {
                return Bloodhound.tokenizers.whitespace(datum.match);
            },
            queryTokenizer: Bloodhound.tokenizers.whitespace,
            remote: {
                url: rootUrl + url + "#%QUERY",
                wildcard: "%QUERY",
                transport: function(opts, onSuccess, onError) {
                    var url = opts.url.split("#")[0];
                    var query = opts.url.split("#")[1];
                    $.ajax({
                        url: url + "/" + query,
                        type: "GET",
                        success: onSuccess,
                        error: onError
                    });
                }
            }
        });
        return adapter.ttAdapter();
    };

    const suggestionMarkup = function(
            value,
            src,
            classNames,
            selClass,
            author,
            CTA
        ) {
            var classNames2 = "align-items-center ";
            if (selClass) {
                classNames2 += selClass;
            } else {
                classNames2 += "d-flex";
            }
            return `<div class="${classNames2}" style="cursor:pointer;padding:9px 12px;font-size:16px">
                ${
                    src
                        ? `<img class="${classNames}" src="${src}" alt="" />`
                        : ""
                }
                <div>
                  ${value}
                  ${
                      author
                          ? `<div class="f-14 text-muted">&mdash; ${author}</div>`
                          : ""
                  }
                </div>
                ${CTA ? `<div class="search-cta">${CTA}</div>` : ""}
              </div>`;
    };

    var allOptions = [
        {
            name: "popular",
            source: getAdapter("popular-search"),
            displayKey: "match",
            async: true,
            limit: 20,
            templates: {
                suggestion: value => {
                    return suggestionMarkup(
                        value.match,
                        null,
                        "w-30 h-20 mr-2",
                        null,
                        value.Subtitle,
                        value.CTA
                    );
                }
            }
        },
        {
            name: "speciality",
            source: getAdapter("speciality-search"),
            displayKey: "match",
            async: true,
            limit: 20,
            templates: {
                header:
                    '<p class="autosuggest-target-header" style="padding:9px 12px;font-size:16px">Speciality</p>',
                suggestion: value => {
                    return suggestionMarkup(
                        value.match,
                        value.icon
                            ? "/img/" + value.icon
                            : "/img/icon-heart-outlne.svg",
                        "w-30 h-20 mr-2"
                    );
                }
            }
        },
        {
            name: "doctors",
            async: true,
            limit: 100,
            source: getAdapter("doctor-search"),
            displayKey: "match",
            templates: {
                header:
                    '<p class="autosuggest-target-header" style="padding:9px 12px;font-size:16px">Doctors</p>',
                suggestion: value =>
                    suggestionMarkup(
                        value.match,
                        value.metadata.Photo || "/img/icon-doctor.svg",
                        "w-30 object-cover rounded-circle h-30 mr-2"
                    )
            }
        },
        {
            name: "procedures",
            source: getAdapter("procedures"),
            displayKey: "match",
            async: true,
            limit: 20,
            templates: {
                header:
                    '<p class="autosuggest-target-header" style="padding:9px 12px;font-size:16px">Procedures</p>',
                suggestion: value => {
                    return suggestionMarkup(
                        value.match,
                        value.icon
                            ? "/img/" + value.icon
                            : "/img/icon-heart-outlne.svg",
                        "w-30 h-30 mr-2"
                    );
                }
            }
        },
        {
            name: "treatments",
            source: getAdapter("treatments"),
            displayKey: "match",
            async: true,
            limit: 20,
            templates: {
                header:
                    '<p class="autosuggest-target-header" style="padding:9px 12px;font-size:16px">Treatments</p>',
                suggestion: value => {
                    return suggestionMarkup(
                        value.match,
                        value.Icon ? "/img/" + value.Icon : null,
                        "w-30 h-30 mr-2"
                    );
                }
            }
        },
        {
            name: "disease",
            displayKey: "match",
            source: getAdapter("disease-search"),
            async: true,
            limit: 20,
            templates: {
                header:
                    '<p class="autosuggest-target-header" style="padding:9px 12px;font-size:16px">Ailments</p>',
                suggestion: value => {
                    return suggestionMarkup(
                        value.match,
                        value.icon
                            ? "/img/" + value.icon
                            : "/img/icon-heart-outlne.svg",
                        "w-30 h-20 mr-2"
                    );
                }
            }
        },
        {
            displayKey: "match",
            name: "hospitals",
            source: getAdapter("hospitals"),
            async: true,
            limit: 20,
            templates: {
                header:
                    '<p class="autosuggest-target-header" style="padding:9px 12px;font-size:16px">Hospitals</p>',
                suggestion: value => {
                    return suggestionMarkup(
                        value.match,
                        value.icon,
                        "w-30 object-cover rounded-circle mr-2 h-30"
                    );
                }
            }
        },
        {
            displayKey: "match",
            name: "technologies",
            source: getAdapter("technolgies"),
            async: true,
            limit: 20,
            templates: {
                header:
                    '<p class="autosuggest-target-header" style="padding:9px 12px;font-size:16px">Technologies</p>',
                suggestion: value => {
                    return suggestionMarkup(
                        value.match,
                        value.icon,
                        "w-30 object-cover rounded-circle h-30 mr-2"
                    );
                }
            }
        },
        {
            displayKey: "match",
            name: "blogs",
            async: true,
            limit: 5,
            source: getAdapter("full-search"),
            displayKey: "name",
            templates: {
                header:
                    '<p class="autosuggest-target-header" style="padding:9px 12px;font-size:16px">Blogs</p>',
                suggestion: value =>
                    suggestionMarkup(
                        value.match,
                        "/img/icon-blade.svg",
                        "w-30 object-contain h-30 mr-2",
                        null,
                        value.Doctor || "Max Healthcare Team"
                    )
            }
        }
    ];

    $(".autosuggest-target").each(function(i, autoSuggest) {
        let limitedOptions = [];
        var limited = autoSuggest.getAttribute("data-search-limited-to");

        if (limited) {
            let temp = limited.split(",");
            for (var x in allOptions) {
                for (var y in temp) {
                    if (allOptions[x].name == temp[y]) {
                        var toPush = Object.assign({}, allOptions[x]);
                        if (
                            temp[y] == "doctors" &&
                            autoSuggest.getAttribute("data-search-ids")
                        ) {
                            let ids = autoSuggest
                                .getAttribute("data-search-ids")
                                .split(",");
                            toPush.templates.suggestion = value => {
                                let id = value.id
                                    .split("~~")[1]
                                    .replace("d", "");
                                if (ids.indexOf(id) > -1) {
                                    return suggestionMarkup(
                                        value.match,
                                        value.metadata.Photo ||
                                            "/img/icon-doctor.svg",
                                        "w-30 object-cover rounded-circle h-30 mr-2"
                                    );
                                } else {
                                    return "<div style='display:none;'></div>";
                                }
                            };
                        }
                        limitedOptions.push(toPush);
                    }
                }
            }
        } else {
            limitedOptions = allOptions;
        }
        var options = [
            {
                input: null,
                hint: true,
                highlight: false,
                minLength: 1,
                classNames: {
                    menu: "autosuggest-target-menu",
                    dataset: "autosuggest-target-item",
                    suggestion: "autosuggest-target-suggestion",
                    cursor: "autosuggest-target-cursor"
                }
            }
        ];

        if (limitedOptions.length == 1) {
            delete limitedOptions[0].templates.header;
        }

        options = options.concat(limitedOptions);
        var selector = "." + autoSuggest.className.replace(/ /gi, ".");
        if (!$(selector).hasClass("tt-input")) {
            $.fn.typeahead.apply($(selector), options);
        }
    });

    $(".autosuggest-target").on("keyup", function(e) {
        if (e.which == 13) {
            // debugger;
            $(".tt-selectable")[0].click();
        }
    });

    $(".search-single").submit(function(e) {
        e.preventDefault();
        $(".tt-selectable")[0].click();
    });

    $(".autosuggest-target").on("typeahead:selected", function(e, datum) {
        $(e.target).removeClass("isLoading");
        if ($(this).attr("data-submit-target")) {
            $(this).typeahead("val", datum.title || datum.match);
            var searchType =
                datum && datum.type
                    ? datum.type
                    : $(this).attr("data-search-limited-to");
            window["gwTermSearched"] &&
                window["gwTermSearched"](
                    searchType,
                    datum.title || datum.match
                );
            $("#" + $(this).attr("data-submit-target")).submit();
            return;
        }
        $(".autosuggest-target").typeahead("val", datum.title || datum.match);

        switch (datum.type) {
            case "Doctor":
                window["gwTermSearched"] &&
                    window["gwTermSearched"]("Doctor", datum.match);
                window.location.href =
                    "/doctor/" +
                    datum.id
                        .split("~~")[0]
                        .replace(/_/gi, "-")
                        .toLowerCase();
                break;
            case "Speciality":
                window["gwTermSearched"] &&
                    window["gwTermSearched"]("Speciality", datum.match);
                window.location.href =
                    "/our-specialities/" +
                    datum.id
                        .split("~~")[0]
                        .replace("_", "-")
                        .toLowerCase();
                break;
            case "Procedure":
                window["gwTermSearched"] &&
                    window["gwTermSearched"]("Procedure", datum.match);
                window.location.href =
                    "/procedures/" +
                    datum.id
                        .split("~~")[0]
                        .replace("_", "-")
                        .toLowerCase();
                break;
            case "Disease":
                window["gwTermSearched"] &&
                    window["gwTermSearched"]("Disease", datum.match);
                window.location.href =
                    "/disease/" +
                    datum.id
                        .split("~~")[0]
                        .replace("_", "-")
                        .toLowerCase();
                break;
            case "Technology":
                window["gwTermSearched"] &&
                    window["gwTermSearched"]("Technology", datum.match);
                window.location.href =
                    "/technologies/" +
                    datum.id
                        .split("~~")[0]
                        .replace("_", "-")
                        .toLowerCase();
                break;
            case "Phrase":
                window["gwTermSearched"] &&
                    window["gwTermSearched"]("Phrase", datum.match);
                window.location.href = datum.action;
                break;
            case "treatments":
                window["gwTermSearched"] &&
                    window["gwTermSearched"]("Treatments", datum.match);
                window.location.href =
                    "/our-specialities/" + datum.id.toLowerCase();
                break;
            case "blog":
                window["gwTermSearched"] &&
                    window["gwTermSearched"]("Blog", datum.match);
                window.location.href = "/blogs/" + datum.id;
                break;
            default:
                window["gwTermSearched"] &&
                    window["gwTermSearched"]("Hospital", datum.match);
                window.location.href = "/hospital-network/" + datum.id;
        }
    });

    $(".autosuggest-target")
        .on("typeahead:asyncrequest", function(e) {
            $(e.target).addClass("isLoading");
            $(".autosuggest-target-menu").addClass("isLoading");
        })
        .on("typeahead:asynccancel typeahead:asyncreceive", function(e) {
            $(e.target).removeClass("isLoading");
            $(".autosuggest-target-menu").removeClass("isLoading");
            var emptyResult = $(".no-search-results");
            // console.log("emptyResult", emptyResult);
            if (emptyResult && emptyResult[0]) {
            } else {
                $(".autosuggest-target-menu").append(
                    suggestionMarkup(
                        "No Results Found",
                        null,
                        "w-30 h-20 mr-2",
                        "no-search-results"
                    )
                );
            }

            var emptyResult2 = $(".results_loading");
            if (emptyResult2 && emptyResult2[0]) {
            } else {
                $(".autosuggest-target-menu").append(
                    suggestionMarkup(
                        "Searching...",
                        null,
                        "w-30 h-20 mr-2",
                        "results_loading"
                    )
                );
            }
        });
}

function initFileInputSelector() {
    function handleChange() {
        var $this = $(this);
        var $valueContainer = $this.parent().find("[data-file-input-value]");
        var choosedFile = Array.from($this.get(0).files);

        if (choosedFile.length > 1) {
            $valueContainer.text(choosedFile.length + " files choosed");
        } else {
            $valueContainer.text("" + choosedFile[0].name);
        }
    }

    $("[data-file-input-target]").on("change", handleChange);
}

function handleMobileNavigationLinks() {
    function removeActiveClass(_e, data) {
        var $target = $(data.target);
        var $trigger = $(data.trigger);
        if (!$target.hasClass("navigation-fullpage-mobile-inner")) return;
        $target.find("a").removeClass("active");
        $trigger.removeClass("active");
    }

    $(document).on("mx.class.remove", removeActiveClass);
}


function initInvestorsmanagementCarousel() {
    if (!$.fn.slick) return;
    $(".investors-management-carousel").slick({
        slidesToShow: 4,
        infinite: false,
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1.03
                }
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 3
                }
            }
        ],
        prevArrow: ".investor-management-control-left",
        nextArrow: ".investor-management-control-right"
    });
}

function initInvestorsCarousel() {
    if (!$.fn.slick) return;
    $(".investors-carousel").slick({
        slidesToShow: 4,
        infinite: false,
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1.03
                }
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 3
                }
            }
        ],
        prevArrow: ".investor-control-left",
        nextArrow: ".investor-control-right"
    });
}


function initDocReelCarousel() {
    if (!$.fn.slick) return;
    $(".doc-reels-slider").slick({
        dots: true,
        slidesToShow: 3.5,
        slidesToScroll: 3,
        infinite: false,
        responsive: [
            {
            breakpoint: 1024,
            settings: {
                slidesToShow: 2.5,
                slidesToScroll: 2.5,
                infinite: true,
                dots: true
            }
            },
            {
            breakpoint: 992,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2
            }
            },
            {
            breakpoint: 768,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                dots: false
            }
            }
        ]
    });
}






function initDocBlogCarousel() {
    if (!$.fn.slick) return;
    $(".doc-blogs-sliderr").slick({
        dots: true,
        infinite: false,
        speed: 300,
        slidesToShow: 4,
        slidesToScroll: 4,
        responsive: [
            {
            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: true,
                dots: true
            }
            },
            {
            breakpoint: 992,
            settings: {
                slidesToShow: 2.2,
                slidesToScroll: 2
            }
            },
            {
            breakpoint: 768,
            settings: {
                slidesToShow: 1.5,
                slidesToScroll: 1,
                dots: false,
                arrows: false

            }
            }
        ]
    });
}



function initDocTalkCarousel() {
    if (!$.fn.slick) return;
    $(".doc-talk-slider").slick({
        dots: true,
        infinite: false,
        speed: 300,
        slidesToShow: 3.8,
        slidesToScroll: 3,
        responsive: [
            {
            breakpoint: 1024,
            settings: {
                slidesToShow: 2.5,
                slidesToScroll: 2,
                infinite: true,
                dots: true
            }
            },
            {
            breakpoint: 992,
            settings: {
                slidesToShow: 2.2,
                slidesToScroll: 2
            }
            },
            {
            breakpoint: 768,
            settings: {
                slidesToShow: 1.2,
                slidesToScroll: 1,
                dots: false,
                arrows: false
            }
            }
        ]
    });
}



function initCommitteesCarousel() {
    if (!$.fn.slick) return;
    $(".committees-carousel").slick({
        slidesToShow: 4,
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1.03
                }
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 3
                }
            }
        ],
        prevArrow: ".committees-control-left",
        nextArrow: ".committees-control-right"
    });
}

function initAnnualReportCarousel() {
    if (!$.fn.slick) return;
    $(".annual-report-carousel").slick({
        slidesToShow: 5,
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1.03
                }
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 3
                }
            }
        ],
        prevArrow: ".annual-report-control-left",
        nextArrow: ".annual-report-control-right"
    });
}

function initNewsMediaCarousel() {
    if (!$.fn.slick) return;
    $(".news-media-carousel").slick({
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    arrows: false,
                    autoplay: true
                }
            }
        ]
    });
}

function handleDoctorCalendarSpacing() {
    var $calendar = $(".date-calendar");
    var $sidebar = $(".doctor-detail-rightpanel");
    var mainSectionHeight = 570;
    var addOnSpacing = 100;

    if (!$calendar.length || !$sidebar.length) return;

    var calendarHeight = $calendar.parent("div").innerHeight();

    var calcHeight =
        Math.abs(mainSectionHeight - calendarHeight) + addOnSpacing;

    $sidebar.css("padding-top", calcHeight + "px");
}

function scrollToView(elm) {
    if (elm) {
        elm.scrollIntoView({
            block: "nearest",
            inline: "start"
        });
    }
}

function initBsTabsSwipeSupport() {
    var x = null;

    function handleTouchStart(e) {
        var touchEvent = e.touches || e.originalEvent.touches;
        x = touchEvent[0].clientX;
    }

    function handleTouchMove(e) {
        var $this = $(this);
        clearTimeout(this.timer);
        this.timer = setTimeout(function() {
            var touchEvent = e.touches || e.originalEvent.touches;
            var diff = Math.abs(touchEvent[0].clientX) - Math.abs(x);
            if (diff < 0) {
                $(document).trigger({
                    type: "mxtab.swipe-right",
                    elm: $this
                });
            } else {
                $(document).trigger({
                    type: "mxtab.swipe-left",
                    elm: $this
                });
            }
            x = null;
        }, 60);
    }

    $(".swipe-tabs-content")
        .on("touchstart", handleTouchStart)
        .on("touchmove", handleTouchMove);

    $(document).on("mxtab.swipe-right", function(e) {
        var $elm = $(e.elm);
        var $tab = $elm
            .prev(".swipe-tabs")
            .find(".active")
            .parent("li")
            .next();
        if ($tab.length) {
            $tab.find("a").tab("show");
            scrollToView($tab.find("a").get(0));
        }
    });

    $(document).on("mxtab.swipe-left", function(e) {
        var $elm = $(e.elm);
        var $tab = $elm
            .prev(".swipe-tabs")
            .find(".active")
            .parent("li")
            .prev();
        if ($tab.length) {
            $tab.find("a").tab("show");
            scrollToView($tab.find("a").get(0));
        }
    });
}

function dropdownHoverControl() {
    var $body = $("body");

    function handleMouseOver(e) {
        var widthBefore = $body.outerWidth();
        var $currentTarget = $(e.currentTarget);
        var $menu = $currentTarget.find(".dropdown-menu");

        $currentTarget.addClass("show");
        $body.addClass("modal-open");
        var widthAfter = $body.outerWidth();
        $body.css("margin-right", widthAfter - widthBefore + "px");
        $menu.addClass("show");
    }

    function handleMouseOut(e) {
        var $currentTarget = $(e.currentTarget);
        var $menu = $currentTarget.find(".dropdown-menu");

        $currentTarget.removeClass("show");
        $body.removeClass("modal-open");
        $body.removeAttr("style");
        $menu.removeClass("show");
    }

    var $trigger = $('[data-toggle="dropdown"]').not(".not-this");
    var $target = $trigger.parent("li");

    $target.on("mouseover", handleMouseOver);
    $target.on("mouseout", handleMouseOut);
    $trigger.on("click", function(e) {
        e.preventDefault();
        e.stopPropagation();
    });
}

function BSTabsSlideEffect() {
    var isSmallDevice = window.matchMedia("(max-width: 991px)");

    function handlePrevTabAnimation() {
        $('a[data-toggle="tab"]').on("hide.bs.tab", function(e) {
            var $old_tab = $($(e.target).attr("href"));
            var $new_tab = $($(e.relatedTarget).attr("href"));

            if ($new_tab.index() < $old_tab.index()) {
                $old_tab
                    .css("position", "relative")
                    .css("right", "0")
                    .show();
                $old_tab.animate(
                    {
                        right: "-100vw"
                    },
                    300,
                    function() {
                        $old_tab.css("right", 0).removeAttr("style");
                    }
                );
            } else {
                $old_tab
                    .css("position", "relative")
                    .css("left", "0")
                    .show();
                $old_tab.animate(
                    {
                        left: "-100vw"
                    },
                    300,
                    function() {
                        $old_tab.css("left", 0).removeAttr("style");
                    }
                );
            }
        });
    }

    function handleNextTabAnimation() {
        $('a[data-toggle="tab"]').on("show.bs.tab", function(e) {
            var $new_tab = $($(e.target).attr("href"));
            var $old_tab = $($(e.relatedTarget).attr("href"));

            if ($new_tab.index() > $old_tab.index()) {
                $new_tab.css("position", "relative").css("right", "-100vw");
                $new_tab.animate(
                    {
                        right: "0"
                    },
                    300
                );
            } else {
                $new_tab.css("position", "relative").css("left", "-100vw");
                $new_tab.animate(
                    {
                        left: "0"
                    },
                    300
                );
            }
        });
    }

    if (isSmallDevice.matches) {
        handlePrevTabAnimation();
        handleNextTabAnimation();
    }
}

function handleSecondLevelMenu() {
    function hideAllMenu() {
        $("[data-toggle-menu]")
            .next(".navigation-fullpage-mobile-dropdown")
            .hide();
    }

    function scrollIt($elm, position) {
        $elm.removeClass("overflow-x-hidden");
        $elm.addClass("hide-scrollbar");
        $elm.stop().animate(
            {
                scrollLeft: position
            },
            300,
            function() {
                $elm.addClass("overflow-x-hidden");
                $elm.removeClass("hide-scrollbar");
            }
        );
    }

    function openMenu(e) {
        e.preventDefault();
        var $target = $(e.currentTarget);
        var $parent = $target.parents(".navigation-fullpage-mobile-dropdown");
        var $menu = $target.next(".navigation-fullpage-mobile-dropdown");
        hideAllMenu();
        $menu.show();
        scrollIt($parent, $parent.innerWidth() + 40);
    }

    function goBack(e) {
        var $target = $(e.currentTarget);
        var $parent = $target.parents(".navigation-fullpage-mobile-dropdown");
        scrollIt($parent, 0);
    }

    $("[data-toggle-menu-back]").on("click", goBack);
    $("[data-toggle-menu]").on("click", openMenu);
}

function toggleByViewportVisiblity() {
    function toggleClass() {
        var $target = $("[data-viewport-control]");
        if (!$target.length) return;
        var targetOffsetTop = $target.offset().top;
        var scrollTop = $(window).scrollTop();
        var offset = 500;

        if (scrollTop > targetOffsetTop - offset) {
            $target.addClass("is-active");
        }
    }
    $(window).on("scroll", toggleClass);
    toggleClass();
}

function mileStoneTransitionDelaySetter() {
    $(".milestone-list li").each(function(index, item) {
        var $target = $(item);
        var dur = 400;
        $target
            .find(".milestone-list-group")
            .css("transition-delay", index * dur + "ms");
        $target
            .find(".milestone-list-title")
            .css("transition-delay", index * dur + "ms");
        $target
            .find(".milestone-list-logo")
            .css("transition-delay", index * dur + "ms");
        $target
            .find(".milestone-list-line")
            .css("transition-delay", index * dur + "ms");
    });
}

function scrollOnFocusSearchField() {
    $(".search-form ng-select").on("click", function() {
        var $this = $(this);
        var $parent = $this.parents(".home-banner-section-search-form");
        var offsetTop = $parent.offset().top;
        $("html, body")
            .stop()
            .animate(
                {
                    scrollTop: offsetTop / 2
                },
                500
            );
    });
}

function scrollToTopHandler() {
    var $trigger = $(".scroll-to-top-trigger");
    $(window).on("scroll", function() {
        var scrollTop = $(window).scrollTop();

        if (scrollTop > 500) {
            $trigger.fadeIn("fast");
        } else {
            $trigger.fadeOut("fast");
        }
    });

    $trigger.on("click", function() {
        $("html,body")
            .stop()
            .animate(
                {
                    scrollTop: 0
                },
                500
            );
    });
}

function handleReadMoreLessScrolling() {
    function handleClick() {
        var $trigger = $(this);
        var text = $trigger.text();
        var $target = $("[data-readmore]");
        if (text.toLowerCase() !== "read less") {
            return;
        }

        setTimeout(function() {
            $("html, body")
                .stop()
                .animate(
                    {
                        scrollTop: $target.offset().top - 130
                    },
                    100
                );
        }, 200);
    }

    $(document).on("click", "[data-readmore-toggle]", handleClick);
}

function animateOnReveal() {
    if (typeof WOW === "function") {
        new WOW().init();
    }
}

function handleHeaderSearch() {
    function handleClick(e) {
        e.preventDefault();
        var $target = $(".header-overlay-search");

        if ($target.hasClass("is-show")) {
            $target.removeClass("is-show");
        } else {
            $target.addClass("is-show");
            setTimeout(function() {
                $target
                    .find("input")
                    .eq(1)
                    .focus();
            }, 400);
        }
    }

    $("#searchIcon")
        .add("#headerOverlayClose")
        .on("click", handleClick);
}

function mobileHeaderSearchHandler() {
    var $trigger = $("#mb-single-search-trigger");
    var $triggerContent;
    var $target = $("#mb-single-search");

    function handleClick() {
        if ($target.is(":hidden")) {
            $target.slideDown();
            $triggerContent = $trigger.html();
            $trigger.html('<span class="f-20 mr-2">&#10005;</span>');
        } else {
            $target.slideUp();
            $trigger.html($triggerContent);
        }
    }

    $trigger.on("click", handleClick);
}

$(function() {
    toggleSearchBar();
    initLocationCarousel();
    toggleGridLayout();
    initSuccessStories();
    initDoctorCarousel();
    initDoctorCarouselIntHome();
    technologyCarousel();
    countryAwardsCarousel();
    initTechnologyCarousel();
    intCountryCenterCarousel();
    initAmenitiesCarousel();
    initIntCenterCarousel();
    initInstituteListCarousel();
    initBlogCarousel();
    initVideoCarousel();
    initDoctorImgCarousel();
    initSingleStoryCarousel();
    initSelectBox();
    mobileNavigationTrigger();
    classToggler();
    handleMegaDropdown();
    handleSearchOverlayMobile();
    initFileInputSelector();
    handleMobileNavigationLinks();
    initInvestorsCarousel();
    initDocReelCarousel();
    initDocBlogCarousel();
    initDocTalkCarousel();
    initInvestorsmanagementCarousel();
    initCommitteesCarousel();
    initAnnualReportCarousel();
    initNewsMediaCarousel();
    initBsTabsSwipeSupport();
    handleDoctorCalendarSpacing();
    BSTabsSlideEffect();
    handleSecondLevelMenu();
    handleTopBarHeader();
    toggleByViewportVisiblity();
    mileStoneTransitionDelaySetter();
    scrollOnFocusSearchField();
    scrollToTopHandler();
    handleReadMoreLessScrolling();
    animateOnReveal();
    handleHeaderSearch();
    investorTabsInit();
    mobileHeaderSearchHandler();
    accordionScrollHandler();
    handleTabScrollOnClick();
    initVideosCarousel();
    initImmigrationDoctorCarousel();
    internationalProcedureCarousel();
    internationalCostCarousel();
    doctorNewCarousel();
    investorReportCarousel();
});

$(function() {
    initAutoSuggest();
});


function handleTabScrollOnClick() {
    var $w = $(window).width();

    function handleTabs() {
        $('a[data-toggle="tab"]').on("shown.bs.tab", function(e) {
            scrollToView(e.target);
        });
    }

    if ($w < 992) {
        handleTabs();
    }
}

function accordionScrollHandler() {
    $(".accordion").on("shown.bs.collapse", function(event) {
        const $currentOpenedContainer = $(event.target);
        setTimeout(function() {
            $("html,body")
                .stop()
                .animate(
                    {
                        scrollTop: $currentOpenedContainer.offset().top - 230
                    },
                    500
                );
        }, 450);
    });
}

function investorTabsInit() {
    var i = 0;
    $("#investor-tabs").on("show.bs.tab", function() {
        if (!i) {
            $(".committees-control-left").trigger("click");
            i = 1;
        }
    });
}

$(function() {
    $(".data-form-btn").on("click", function() {
        $("#plan-form").show();
    });
});

const counter = document.querySelectorAll(".counter");

Object.keys(counter).forEach(idx => {
    const el = counter[idx];

    const target = el.innerText;

    if (!el.hasAttribute("id")) {
        el.setAttribute("id", `counter${idx}`);
    }

    const countUp = new CountUp(el.getAttribute("id"), target);

    if (!countUp.error) {
        countUp.start();
    } else {
        console.error(countUp.error);
    }
});

// const toggle = document.getElementById("secondOpinionToggle");

// toggle.addEventListener(
//     "click",
//     e => {
//         e.stopPropagation();

//         e.preventDefault();

//         toggleSecondOpinion(toggle);
//     },
//     true
// );

const toggleSecondOpinion = toggle => {
    const toggleTarget = toggle.nextElementSibling;

    toggleTarget.classList.toggle("show");

    toggle.classList.toggle("collapsed");

    toggleTarget.firstElementChild.classList.toggle("show");
};

$(function() {
    /* const searchIcon = $("#searchIcon");
    searchIcon.hide(); */
    const apptBtn = document.getElementById("apptBtn");
    const megaDrop = document.querySelector(".megadropdown");
    $(window).on("scroll", function() {
        if ($(this).scrollTop() >= 400) {
            // searchIcon.show();
            if (!megaDrop.classList.contains("appear")) {
                megaDrop.classList.add("appear");
            }
            apptBtn.classList.add("show");
        } else {
            // searchIcon.hide();
            apptBtn.classList.remove("show");
            if (megaDrop.classList.contains("appear")) {
                megaDrop.classList.remove("appear");
            }
        }
    });
});


function intCountryCenterCarousel() {
    if (!$.fn.slick) return;
    $(".int-county-center-crausel").slick({
        slidesToShow: 5,
        slidesToScroll: 2, // Slides 3 items per click
        infinite: false,
        arrows: true,
        variableWidth: true,
        prevArrow: '<button type="button" class="slick-prev"></button>',
        nextArrow: '<button type="button" class="slick-next"></button>',
        responsive: [{
                breakpoint: 1199,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 2 // Adjust scroll for this breakpoint
                }
            },
            {
                breakpoint: 1023,
                settings: {
                    slidesToShow: 4.1,
                    slidesToScroll: 2 // Adjust scroll for this breakpoint
                }
            },
            {
                breakpoint: 767,
                settings: {
                    arrows: false,
                    infinite: true,
                    speed: 500,
                    cssEase: 'ease',
                    slidesToShow: 1, // Show only 1 slide for mobile view
                    slidesToScroll: 1 // Scroll 1 slide at a time on mobile
                }
            }
        ]
    });
}
const toggleDropDown = toggle => {
    const toggleTarget = document.getElementById(
        toggle.getAttribute("toggle-menu")
    );

    toggle.parentElement.classList.toggle("show");

    toggle.setAttribute(
        "aria-expanded",
        !Boolean(toggle.getAttribute("aria-expanded"))
    );

    toggleTarget.classList.toggle("show");
};

const toggles = document.querySelectorAll(".megadropdown .dropdown-toggle");

Object.keys(toggles).forEach(idx => {
    const toggle = toggles[idx];

    toggle.addEventListener(
        "click",
        e => {
            e.preventDefault();

            e.stopPropagation();

            Object.keys(toggles).forEach(ix => {
                if (
                    toggles[ix] !== toggle &&
                    toggles[ix].parentElement.classList.contains("show")
                ) {
                    toggleDropDown(toggles[ix]);
                }
            });

            toggleDropDown(toggle);
        },
        true
    );
});

const dropmenus = document.querySelectorAll(
    ".megadropdown-menu .megadropdown-menu-close"
);

Object.keys(dropmenus).forEach(idx => {
    const menu = dropmenus[idx];

    const menuParent = document.getElementById(
        menu.parentElement.parentElement.getAttribute("toggle-parent")
    );

    menu.addEventListener(
        "click",
        e => {
            e.preventDefault();

            e.stopPropagation();

            menu.parentElement.parentElement.classList.toggle("show");

            menuParent.setAttribute(
                "aria-expanded",
                !Boolean(menuParent.getAttribute("aria-expanded"))
            );

            menuParent.parentElement.classList.toggle("show");
        },
        true
    );
});


var path = window.location.pathname;
var pathSegments = path.split('/');
var desiredSegment = pathSegments[1];


if(desiredSegment!='investors-new' && desiredSegment!='investor-new' && desiredSegment!='qip-pd-document-2020-new'){
    window.addEventListener("keydown",function(event){const key=event.key!==undefined?event.key:event.keyIdentifier!==undefined?event.keyIdentifier:event.keyCode!==undefined?event.keyCode:null;let isCtrl;isCtrl=event.ctrlKey?true:false;const search=document.getElementsByClassName("header-overlay-search");if(isCtrl){if("f"==key){event.preventDefault();event.stopImmediatePropagation();if(search.length&&!search[0].classList.contains("is-show")){search[0].classList.add("is-show");setTimeout(function(){$(search[0]).find("input").eq(1).focus()},400)}}}else{if("Escape"==key){event.preventDefault();event.stopImmediatePropagation();if(search.length&&search[0].classList.contains("is-show")){search[0].classList.remove("is-show")}}}});
}

function internationalProcedureCarousel(){
    if (!$.fn.slick) return;
    $('.procedures-items').slick({
        slidesToShow: 4,
        loop: true,
        infinite: false,
        slidesToScroll: 4,
        responsive: [
          {
            breakpoint: 1199,
            settings: {
              slidesToShow: 3.8,
              slidesToScroll: 3,
            },
          },
          {
            breakpoint: 850,
            settings: {
              slidesToShow: 2.5,
              arrows: false,
              slidesToScroll: 2,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2.1,
              arrows: false,
              slidesToScroll: 1,
              dots: false,
            },
          },
        ],
        prevArrow: ".network-section-carousel-prev1",
        nextArrow: ".network-section-carousel-next1"
      });

}

function doctorNewCarousel(){
    if (!$.fn.slick) return;
    $('.doctor-slider-new').slick({
        slidesToShow: 2,
        loop: true,
        infinite: false,
        slidesToScroll: 2,
        responsive: [
          {
            breakpoint: 1199,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
            },
          },
          {
            breakpoint: 850,
            settings: {
              slidesToShow: 1.6,
              arrows: false,
              slidesToScroll: 1.6,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1.3,
              arrows: false,
              slidesToScroll: 1,
              dots: false,
            },
          },
        ],
        prevArrow: ".network-section-carousel-prev1",
        nextArrow: ".network-section-carousel-next1"
      });

}



function internationalCostCarousel(){
    if (!$.fn.slick) return;
    $('.cost-items').slick({
        slidesToShow: 4,
        loop: true,
        infinite: false,
        slidesToScroll: 4,
        responsive: [
          {
            breakpoint: 1199,
            settings: {
              slidesToShow: 3.8,
              slidesToScroll: 3,
            },
          },
          {
            breakpoint: 850,
            settings: {
              slidesToShow: 2.5,
              arrows: false,
              slidesToScroll: 2,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              arrows: false,
              slidesToScroll: 1,
              dots: false,
            },
          },
        ],
        prevArrow: ".network-section-carousel-prev1",
        nextArrow: ".network-section-carousel-next1"
      });

}



function initVideosCarousel() {
    if (!$.fn.slick) return;
    $(".videos-carousel").slick({
        slidesToShow: 4,
        infinite: false,
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1.03
                }
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 3
                }
            }
        ],
    });
}

function initImmigrationDoctorCarousel() {
    if (!$.fn.slick) return;
    $('.immigration-doctors-slider').slick({
        infinite: true,
        dots: true,
        slidesToShow: 5,
        slidesToScroll: 5,
        arrows: false,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    arrows: false,
                    dots: true
                }
            },
            {
                breakpoint: 900,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 500,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    });
}







function handleAccordion() {
    if ($(window).width() < 768) {
        $('#accordionCPSInterlink .collapse').removeClass('show');
        $('#accordionUnitInterlink .collapse').removeClass('show');
        $('#accordionDoctorUnitInterlink .collapse').removeClass('show');
        $('#accordionTopSpecialityInterlink .collapse').removeClass('show');
        $('#accordionHealthCalInterlink .collapse').removeClass('show');
        $('#accordionBlogInterlink .collapse').removeClass('show');
        
        $('#accordionCPSInterlink h2').attr('data-toggle', 'collapse');
        $('#accordionUnitInterlink h2').attr('data-toggle', 'collapse');
        $('#accordionDoctorUnitInterlink h2').attr('data-toggle', 'collapse');
        $('#accordionTopSpecialityInterlink h2').attr('data-toggle', 'collapse');
        $('#accordionHealthCalInterlink h2').attr('data-toggle', 'collapse');
        $('#accordionBlogInterlink h2').attr('data-toggle', 'collapse');
    } else {
        $('#accordionCPSInterlink .collapse').addClass('show');
        $('#accordionUnitInterlink .collapse').addClass('show');
        $('#accordionDoctorUnitInterlink .collapse').addClass('show');
        $('#accordionTopSpecialityInterlink .collapse').addClass('show');
        $('#accordionHealthCalInterlink .collapse').addClass('show');
        $('#accordionBlogInterlink .collapse').addClass('show');

        $('#accordionCPSInterlink h2').removeAttr('data-toggle');
        $('#accordionUnitInterlink h2').removeAttr('data-toggle');
        $('#accordionDoctorUnitInterlink h2').removeAttr('data-toggle');
        $('#accordionTopSpecialityInterlink h2').removeAttr('data-toggle');
        $('#accordionHealthCalInterlink h2').removeAttr('data-toggle');
        $('#accordionBlogInterlink h2').removeAttr('data-toggle');
    }
}




$('.siteHeadingCPSInterlinking').on('click', function() {
    $('#accordionCPSImg').toggleClass('rotate-180');
});
$('.siteHeadingUnitInterlinking').on('click', function() {           
        $('#accordionUnitImage').toggleClass('rotate-180');
});
$('.siteHeadingSpecialistInterlinking').on('click', function() {           
    $('#accordionSpecialistImage').toggleClass('rotate-180');
});
$('.siteHeadingTopSpecialityInterlinking').on('click', function() {           
    $('#accordionTopSpecialitiesImage').toggleClass('rotate-180');
});
$('.siteHeadingHealthCalInterlinking').on('click', function() {           
    $('#accordionHealthCalculatorImage').toggleClass('rotate-180');
});
$('.siteHeadingBlogInterlinking').on('click', function() {           
    $('#accordionBlogImage').toggleClass('rotate-180');
});

(function handleAccordion() {
    if ($(window).width() < 768) {
        $('.collapse').removeClass('show');
        $('h2').attr('data-toggle', 'collapse');
    } else {
        $('.collapse').addClass('show');
        $('h2').removeAttr('data-toggle');
    }
})();

$(window).resize(function () {
    handleAccordion();
});
