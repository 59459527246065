import { recaptchaInit } from "../js/recaptcha.js";

$(() => {
    $("#time-to-call").niceSelect();

    $("#time-to-call-sidebar").niceSelect();

    $(
        "form#second-opinion-header #second-opinion-header-file, form#second-opinion-sidebar #second-opinion-sidebar-file, form#second-opinion #second-opinion-sidebar-file"
    ).on("change", event => {
        event.stopPropagation();
        let f = event.target.files[0];
        if (f.size >= 5 * 1024 * 1024) {
            Toast.fire({
                icon: "warning",
                title: "Only upload file of maximum 5MB!"
            });
            event.target.value = null;
            $(event.target)
                .siblings("[data-file-input-value]")
                .empty()
                .text("Browse PDF,JPG,DOC...");
            event.preventDefault();
            return false;
        }
        if (!f.type.match(
                "(image/jp.*|application/pdf|application/doc.*|image/pn.*|application/vnd.openxmlformats-officedocument.wordprocessingml.document)"
            )) {
            if (window.hasOwnProperty("Toast")) {
                Toast.fire({
                    icon: "warning",
                    title: "We Support PDF, DOC, JPG & PNG!"
                });
            }
            event.target.value = null;
            $(event.target)
                .siblings("[data-file-input-value]")
                .empty()
                .text("Browse PDF,JPG,DOC...");
            event.preventDefault();
            return false;
        }

        let fl = new FileReader();
        fl.onload = e => {
            let bs = new Uint8Array(e.target.result);
            let i =
                bs.length > 4 &&
                bs[0] == 0xff &&
                bs[1] == 0xd8 &&
                bs[2] == 0xff &&
                bs[3] == 0xe0;
            let x =
                bs.length > 4 &&
                bs[0] == 0x50 &&
                bs[1] == 0x4b &&
                bs[2] == 0x03 &&
                bs[3] == 0x04;
            let dx =
                bs.length > 4 &&
                bs[0] == 0x50 &&
                bs[1] == 0x4b &&
                bs[2] == 0x05 &&
                bs[3] == 0x06;
            let dcx =
                bs.length > 4 &&
                bs[0] == 0x50 &&
                bs[1] == 0x4b &&
                bs[2] == 0x07 &&
                bs[3] == 0x08;
            let p =
                bs.length > 5 &&
                bs[0] == 0x25 &&
                bs[1] == 0x50 &&
                bs[2] == 0x44 &&
                bs[3] == 0x46 &&
                bs[4] == 0x2d;
            let d =
                bs.length > 8 &&
                bs[0] == 0xd0 &&
                bs[1] == 0xcf &&
                bs[2] == 0x11 &&
                bs[3] == 0xe0 &&
                bs[4] == 0xa1 &&
                bs[5] == 0xb1 &&
                bs[6] == 0x1a &&
                bs[7] == 0xe1;
            let g =
                bs.length > 8 &&
                bs[0] == 0x89 &&
                bs[1] == 0x50 &&
                bs[2] == 0x4e &&
                bs[3] == 0x47 &&
                bs[4] == 0x0d &&
                bs[5] == 0x0a &&
                bs[6] == 0x1a &&
                bs[7] == 0x0a;
            if (i || p || d || g || x || dx || dcx) {} else {
                if (window.hasOwnProperty("Toast")) {
                    Toast.fire({
                        icon: "warning",
                        title: "We Support PDF, DOC, JPG & PNG!"
                    });
                }
                event.target.value = null;
                $(event.target)
                    .siblings("[data-file-input-value]")
                    .empty()
                    .text("Browse PDF,JPG,DOC...");
                event.preventDefault();
                return false;
            }
        };
        fl.readAsArrayBuffer(f);
        console.log("file uploadeeddd");
    });

    // $("input[data-press-name]").on("keypress", function(e) {
    //     if (!/^[A-Za-z\s]$/.test(e.key)) e.preventDefault();
    // });

    $("input[data-press-name]").on("input", function(e) {
        // Only allow letters (A-Z, a-z) and spaces
        if (!/^[A-Za-z\s]+$/.test(e.target.value)) {
            // Remove the last invalid character entered
            e.target.value = e.target.value.replace(/[^A-Za-z\s]/g, '');
        }
    });


    // $("input[data-press-email]").on("keypress", function(e) {
    //     if (e.target.value.length > 4) {
    //         e.target.classList.contains("is-invalid") ?
    //             e.target.classList.remove("is-invalid") :
    //             "";
    //     }
    //     if (!/^[a-z0-9._%+-@]$/.test(e.key)) e.preventDefault();
    // });


    $("input[data-press-email]").on("input", function(e) {
        // Remove 'is-invalid' class if the length is greater than 4
        if (e.target.value.length > 4) {
            if (e.target.classList.contains("is-invalid")) {
                e.target.classList.remove("is-invalid");
            }
        }

        // Only allow valid email characters (lowercase letters, numbers, and ._%+-@)
        if (!/^[a-z0-9._%+-@]+$/.test(e.target.value)) {
            // Remove invalid characters
            e.target.value = e.target.value.replace(/[^a-z0-9._%+-@]/g, '');
        }
    });


    // $("input[data-press-phone]").on("keypress", function(e) {
    //     if (!/^[0-9]$/.test(e.key)) e.preventDefault();
    //     const inp = e.currentTarget;
    //     if (inp.getAttribute("dial") === "+91") {
    //         if (e.target.value.length === 10) {
    //             e.target.classList.contains("is-invalid") ?
    //                 e.target.classList.remove("is-invalid") :
    //                 "";
    //         }
    //         if (e.target.value.length > 9) e.preventDefault();
    //     } else {
    //         if (e.target.value.length >= 10 && e.target.value.length <= 14) {
    //             e.target.classList.contains("is-invalid") ?
    //                 e.target.classList.remove("is-invalid") :
    //                 "";
    //         }

    //         if (e.target.value.length > 13) e.preventDefault();
    //     }
    // });




$("input[data-press-phone]").on("input", function(e) {
    const inp = e.currentTarget;
    const dialCode = inp.getAttribute("dial");

    // Remove any non-numeric characters
    e.target.value = e.target.value.replace(/[^0-9]/g, '');

    // For India (+91), restrict to 10 digits
    if (dialCode === "+91") {
        if (e.target.value.length === 10) {
            // Remove 'is-invalid' class when the number is valid
            if (e.target.classList.contains("is-invalid")) {
                e.target.classList.remove("is-invalid");
            }
        }
        // Prevent further input if length exceeds 10 digits
        if (e.target.value.length > 10) {
            e.target.value = e.target.value.slice(0, 10);
        }
    } else {
        // For other countries, restrict to 8-14 digits
        if (e.target.value.length >= 8 && e.target.value.length <= 14) {
            // Remove 'is-invalid' class when the number is valid
            if (e.target.classList.contains("is-invalid")) {
                e.target.classList.remove("is-invalid");
            }
        }
        // Prevent further input if length exceeds 14 digits
        if (e.target.value.length > 14) {
            e.target.value = e.target.value.slice(0, 14);
        }
    }
});



    const teleDialError = document.querySelectorAll("input[tele-dial-error]");
    teleDialError.forEach(td => {
        const intlp = intlTelInput(td, {
            initialCountry: localStorage.getItem("currentCountryCode") ?? "IN",
            separateDialCode: true
        });
        td.setAttribute("dial", `+${intlp.getSelectedCountryData().dialCode}`);

        td.addEventListener("countrychange", function(e) {
            td.setAttribute(
                "dial",
                `+${intlp.getSelectedCountryData().dialCode}`
            );
            if (td.getAttribute("dial") === "+91") {
                if (td.value.length >= 10) {
                    td.value = td.value.substring(0, 10);
                }
            }
        });
    });

    const teleDial = document.querySelectorAll("input[tele-dial]");
    teleDial.forEach(td => {
        const intlp = intlTelInput(td, {
            initialCountry: localStorage.getItem("currentCountryCode") ?? "IN",
            separateDialCode: true
        });
        td.setAttribute("dial", `+${intlp.getSelectedCountryData().dialCode}`);

        td.addEventListener("countrychange", function(e) {
            td.setAttribute(
                "dial",
                `+${intlp.getSelectedCountryData().dialCode}`
            );
            if (td.getAttribute("dial") === "+91") {
                if (td.value.length >= 10) {
                    td.value = td.value.substring(0, 10);
                }
            }
        });
    });

    const teleDialInt2 = document.querySelectorAll("input[tele-dial-int-desk]");
    const intCountry2 = document.getElementById("int-country-desk");

    teleDialInt2.forEach(td => {
        const intlp = intlTelInput(td, {
            initialCountry: localStorage.getItem("currentCountryCode") ?? "IN",
            separateDialCode: true
        });

        console.log("testing", intlp.getSelectedCountryData());

        intCountry2.setAttribute('value', intlp.getSelectedCountryData().name);

        td.setAttribute("dial", `+${intlp.getSelectedCountryData().dialCode}`);

        td.addEventListener("countrychange", function(e) {
            td.setAttribute(
                "dial",
                `+${intlp.getSelectedCountryData().dialCode}`
            );
            if (td.getAttribute("dial") === "+91") {
                if (td.value.length >= 10) {
                    td.value = td.value.substring(0, 10);
                }
            }

            console.log("chnage testing", intlp.getSelectedCountryData());
            intCountry2.setAttribute('value', intlp.getSelectedCountryData().name);
        });
    });



    const unitTeleDialSpeciality3 = document.querySelectorAll("input[second-unit-tele-dial3]");
    const unitSeconadOpinionCountrySpeciality3 = document.getElementById("speciality-country3");

    unitTeleDialSpeciality3.forEach(td => {
        const intlp = intlTelInput(td, {
            initialCountry: "IN",
            separateDialCode: true
        });
        unitSeconadOpinionCountrySpeciality3.setAttribute('value', intlp.getSelectedCountryData().name);
        td.setAttribute("dial", `+${intlp.getSelectedCountryData().dialCode}`);
        td.addEventListener("countrychange", function(e) {
            td.setAttribute(
                "dial",
                `+${intlp.getSelectedCountryData().dialCode}`
            );
            if (td.getAttribute("dial") === "+91") {
                if (td.value.length >= 10) {
                    td.value = td.value.substring(0, 10);
                }
            }

            unitSeconadOpinionCountrySpeciality3.setAttribute('value', intlp.getSelectedCountryData().name);
        });
    });




    const unitTeleDial = document.querySelectorAll("input[second-unit-tele-dial]");
    const unitSeconadOpinionCountry = document.getElementById("unit-country");

    unitTeleDial.forEach(td => {
        const intlp = intlTelInput(td, {
            initialCountry: "IN",
            separateDialCode: true
        });
        unitSeconadOpinionCountry.setAttribute('value', intlp.getSelectedCountryData().name);
        td.setAttribute("dial", `+${intlp.getSelectedCountryData().dialCode}`);
        td.addEventListener("countrychange", function(e) {
            td.setAttribute(
                "dial",
                `+${intlp.getSelectedCountryData().dialCode}`
            );
            if (td.getAttribute("dial") === "+91") {
                if (td.value.length >= 10) {
                    td.value = td.value.substring(0, 10);
                }
            }

            unitSeconadOpinionCountry.setAttribute('value', intlp.getSelectedCountryData().name);
        });
    });



    const secondTeleDial = document.querySelectorAll("input[second-tele-dial]");
    const secondOpinionCountry = document.getElementById("second-opinion-country");
    // console.log("secondTeleDial",secondTeleDial);
    secondTeleDial.forEach(td => {
        const intlp = intlTelInput(td, {
            initialCountry: "IN",
            separateDialCode: true
        });
        // console.log("testing---", intlp.getSelectedCountryData());
        secondOpinionCountry.setAttribute('value', intlp.getSelectedCountryData().name);
        td.setAttribute("dial", `+${intlp.getSelectedCountryData().dialCode}`);
        td.addEventListener("countrychange", function(e) {
            td.setAttribute(
                "dial",
                `+${intlp.getSelectedCountryData().dialCode}`
            );
            if (td.getAttribute("dial") === "+91") {
                if (td.value.length >= 10) {
                    td.value = td.value.substring(0, 10);
                }
            }
            // console.log("chnage testing", intlp.getSelectedCountryData());
            secondOpinionCountry.setAttribute('value', intlp.getSelectedCountryData().name);
        });
    });




    const callbackTeleDial = document.querySelectorAll("input[tele-dial]");
    const callbackCountry = document.getElementById("callback-country");

    callbackTeleDial.forEach(td => {
        const intlp = intlTelInput(td, {
            initialCountry: "IN",
            separateDialCode: true
        });
        // console.log("testing---", intlp.getSelectedCountryData());
        callbackCountry.setAttribute('value', intlp.getSelectedCountryData().name);
        td.setAttribute("dial", `+${intlp.getSelectedCountryData().dialCode}`);
        td.addEventListener("countrychange", function(e) {
            td.setAttribute(
                "dial",
                `+${intlp.getSelectedCountryData().dialCode}`
            );
            if (td.getAttribute("dial") === "+91") {
                if (td.value.length >= 10) {
                    td.value = td.value.substring(0, 10);
                }
            }
            // console.log("chnage testing", intlp.getSelectedCountryData());
            callbackCountry.setAttribute('value', intlp.getSelectedCountryData().name);
        });
    });

    const blogTeleDial = document.querySelectorAll("input[second-blog-tele-dial]");
    const blogSeconadOpinionCountry = document.getElementById("second-opinion-blog-country");
    blogTeleDial.forEach(td => {
        const intlp = intlTelInput(td, {
            initialCountry: "IN",
            separateDialCode: true
        });
        // console.log("testing---", intlp.getSelectedCountryData());
        blogSeconadOpinionCountry.setAttribute('value', intlp.getSelectedCountryData().name);
        td.setAttribute("dial", `+${intlp.getSelectedCountryData().dialCode}`);
        td.addEventListener("countrychange", function(e) {
            td.setAttribute(
                "dial",
                `+${intlp.getSelectedCountryData().dialCode}`
            );
            if (td.getAttribute("dial") === "+91") {
                if (td.value.length >= 10) {
                    td.value = td.value.substring(0, 10);
                }
            }
            // console.log("chnage testing", intlp.getSelectedCountryData());
            blogSeconadOpinionCountry.setAttribute('value', intlp.getSelectedCountryData().name);
        });
    });


    const secondOpinionUnitTeleDial = document.querySelectorAll("input[second-opinion-unit-tele-dial]");
    const secondOpinionUnitSeconadOpinionCountry = document.getElementById("second-opinion-unit-country");
    secondOpinionUnitTeleDial.forEach(td => {
        const intlp = intlTelInput(td, {
            initialCountry: "IN",
            separateDialCode: true
        });
        // console.log("testing---", intlp.getSelectedCountryData());
        secondOpinionUnitSeconadOpinionCountry.setAttribute('value', intlp.getSelectedCountryData().name);
        td.setAttribute("dial", `+${intlp.getSelectedCountryData().dialCode}`);
        td.addEventListener("countrychange", function(e) {
            td.setAttribute(
                "dial",
                `+${intlp.getSelectedCountryData().dialCode}`
            );
            if (td.getAttribute("dial") === "+91") {
                if (td.value.length >= 10) {
                    td.value = td.value.substring(0, 10);
                }
            }
            // console.log("chnage testing", intlp.getSelectedCountryData());
            secondOpinionUnitSeconadOpinionCountry.setAttribute('value', intlp.getSelectedCountryData().name);
        });
    });



    const teleDialInt1 = document.querySelectorAll("input[tele-dial-int1]");
    const intCountry1 = document.getElementById("int-country1");




    teleDialInt1.forEach(td => {
        const intlp = intlTelInput(td, {
            initialCountry: "IN",
            separateDialCode: true
        });

        console.log("testing1", intlp.getSelectedCountryData().dialCode);

        intCountry1.setAttribute('value', intlp.getSelectedCountryData().name);

        td.setAttribute("dial", `+${intlp.getSelectedCountryData().dialCode}`);

        td.addEventListener("countrychange", function(e) {
            td.setAttribute(
                "dial",
                `+${intlp.getSelectedCountryData().dialCode}`
            );
            if (td.getAttribute("dial") != "+91" || intlp.getSelectedCountryData().name !== "India (भारत)") {
                if (td.value.length >= 14) {
                    td.value = td.value.substring(0, 14);
                }
            }

            console.log("chnage testing", intlp.getSelectedCountryData());
            intCountry1.setAttribute('value', intlp.getSelectedCountryData().name);
        });
    });





    const teleDialInt4 = document.querySelectorAll("input[tele-dial-int4]");
    const intCountry4 = document.getElementById("int-country4");

    teleDialInt4.forEach(td => {
        const intlp = intlTelInput(td, {
            initialCountry: "IN",
            separateDialCode: true
        });

        console.log("testing3", intlp.getSelectedCountryData().dialCode);

        intCountry4.setAttribute('value', intlp.getSelectedCountryData().name);

        td.setAttribute("dial", `+${intlp.getSelectedCountryData().dialCode}`);

        td.addEventListener("countrychange", function(e) {
            td.setAttribute(
                "dial",
                `+${intlp.getSelectedCountryData().dialCode}`
            );
            if (td.getAttribute("dial") === "+91") {
                if (td.value.length >= 10) {
                    td.value = td.value.substring(0, 10);
                }
            }

            console.log("chnage testing", intlp.getSelectedCountryData());
            intCountry4.setAttribute('value', intlp.getSelectedCountryData().name);
        });
    });




    const teleDialIntDocModal = document.querySelectorAll("input[tele-dial-int-doc-modal]");
    const intCountryDocModal = document.getElementById("int-country-doc-modal");

    teleDialIntDocModal.forEach(td => {
        const intlp = intlTelInput(td, {
            initialCountry: "IN",
            separateDialCode: true
        });

        console.log("testing", intlp.getSelectedCountryData());

        intCountryDocModal.setAttribute('value', intlp.getSelectedCountryData().name);

        td.setAttribute("dial", `+${intlp.getSelectedCountryData().dialCode}`);

        td.addEventListener("countrychange", function(e) {
            td.setAttribute(
                "dial",
                `+${intlp.getSelectedCountryData().dialCode}`
            );
            if (td.getAttribute("dial") === "+91") {
                if (td.value.length >= 10) {
                    td.value = td.value.substring(0, 10);
                }
            }

            console.log("chnage testing", intlp.getSelectedCountryData());
            intCountryDocModal.setAttribute('value', intlp.getSelectedCountryData().name);
        });
    });



    const teleDialInt3 = document.querySelectorAll("input[tele-dial-int3]");
    const intCountry3 = document.getElementById("int-country3");

    teleDialInt3.forEach(td => {
        const intlp = intlTelInput(td, {
            initialCountry: "IN",
            separateDialCode: true
        });

        console.log("testing3", intlp.getSelectedCountryData().dialCode);

        intCountry3.setAttribute('value', intlp.getSelectedCountryData().name);

        td.setAttribute("dial", `+${intlp.getSelectedCountryData().dialCode}`);

        td.addEventListener("countrychange", function(e) {
            td.setAttribute(
                "dial",
                `+${intlp.getSelectedCountryData().dialCode}`
            );
            if (td.getAttribute("dial") === "+91") {
                if (td.value.length >= 10) {
                    td.value = td.value.substring(0, 10);
                }
            }

            console.log("chnage testing", intlp.getSelectedCountryData());
            intCountry3.setAttribute('value', intlp.getSelectedCountryData().name);
        });
    });




    const teleDialInt = document.querySelectorAll("input[tele-dial-int]");
    const intCountry = document.getElementById("int-country");

    teleDialInt.forEach(td => {
        const intlp = intlTelInput(td, {
            initialCountry: localStorage.getItem("currentCountryCode") ?? "IN",
            separateDialCode: true
        });

        console.log("testing", intlp.getSelectedCountryData());

        intCountry.setAttribute('value', intlp.getSelectedCountryData().name);

        td.setAttribute("dial", `+${intlp.getSelectedCountryData().dialCode}`);

        td.addEventListener("countrychange", function(e) {
            td.setAttribute(
                "dial",
                `+${intlp.getSelectedCountryData().dialCode}`
            );
            if (td.getAttribute("dial") === "+91") {
                if (td.value.length >= 10) {
                    td.value = td.value.substring(0, 10);
                }
            }

            console.log("chnage testing", intlp.getSelectedCountryData());
            intCountry.setAttribute('value', intlp.getSelectedCountryData().name);
        });
    });
    



    const teleDialBlog = document.querySelectorAll("input[tele-dial-blog]");
    
    const blogCountry = document.getElementById("blog-country");

    teleDialBlog.forEach(td => {
        const intlp = intlTelInput(td, {
            initialCountry: "IN",
            separateDialCode: true
        });

        console.log("testing", intlp.getSelectedCountryData());

        blogCountry.setAttribute('value', intlp.getSelectedCountryData().name);

        td.setAttribute("dial", `+${intlp.getSelectedCountryData().dialCode}`);

        td.addEventListener("countrychange", function(e) {
            td.setAttribute(
                "dial",
                `+${intlp.getSelectedCountryData().dialCode}`
            );
            if (td.getAttribute("dial") === "+91") {
                if (td.value.length >= 10) {
                    td.value = td.value.substring(0, 10);
                }
            }

            console.log("chnage testing", intlp.getSelectedCountryData());
            blogCountry.setAttribute('value', intlp.getSelectedCountryData().name);
        });
    });



    const teleDialAcademic = document.querySelectorAll("input[tele-dial-academic]");
    const academicCountry = document.getElementById("academic-country");

    teleDialAcademic.forEach(td => {
        const intlp = intlTelInput(td, {
            initialCountry: "IN",
            separateDialCode: true
        });

        console.log("testing", intlp.getSelectedCountryData());

        academicCountry.setAttribute('value', intlp.getSelectedCountryData().name);

        td.setAttribute("dial", `+${intlp.getSelectedCountryData().dialCode}`);

        td.addEventListener("countrychange", function(e) {
            td.setAttribute(
                "dial",
                `+${intlp.getSelectedCountryData().dialCode}`
            );
            if (td.getAttribute("dial") === "+91") {
                if (td.value.length >= 10) {
                    td.value = td.value.substring(0, 10);
                }
            }

            console.log("chnage testing", intlp.getSelectedCountryData());
            academicCountry.setAttribute('value', intlp.getSelectedCountryData().name);
        });
    });


    const teleDialCommon = document.querySelectorAll("input[tele-dial-callback]");
    const countryCommon = document.getElementById("country");

    teleDialCommon.forEach(td => {
        const intlp = intlTelInput(td, {
            initialCountry: "IN",
            separateDialCode: true
        });

        console.log("testing", intlp.getSelectedCountryData());

        countryCommon.setAttribute('value', intlp.getSelectedCountryData().name);

        td.setAttribute("dial", `+${intlp.getSelectedCountryData().dialCode}`);

        td.addEventListener("countrychange", function(e) {
            td.setAttribute(
                "dial",
                `+${intlp.getSelectedCountryData().dialCode}`
            );
            if (td.getAttribute("dial") === "+91") {
                if (td.value.length >= 10) {
                    td.value = td.value.substring(0, 10);
                }
            }

            console.log("chnage testing", intlp.getSelectedCountryData());
            countryCommon.setAttribute('value', intlp.getSelectedCountryData().name);
        });
    });


    const teleDialBlogCommon = document.querySelectorAll("input[tele-dial-common-int]");
    const blogCountryCommon = document.getElementById("common-int-country");

    teleDialBlogCommon.forEach(td => {
        const intlp = intlTelInput(td, {
            initialCountry: "IN",
            separateDialCode: true
        });

        console.log("testing", intlp.getSelectedCountryData());

        blogCountryCommon.setAttribute('value', intlp.getSelectedCountryData().name);

        td.setAttribute("dial", `+${intlp.getSelectedCountryData().dialCode}`);

        td.addEventListener("countrychange", function(e) {
            td.setAttribute(
                "dial",
                `+${intlp.getSelectedCountryData().dialCode}`
            );
            if (td.getAttribute("dial") === "+91") {
                if (td.value.length >= 10) {
                    td.value = td.value.substring(0, 10);
                }
            }

            console.log("chnage testing", intlp.getSelectedCountryData());
            blogCountryCommon.setAttribute('value', intlp.getSelectedCountryData().name);
        });
    });


    String.prototype.toSnake = function() {
        return this.match(
                /[A-Z]{3,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g
            )
            .map(s => s.toLowerCase())
            .join("_");
    };

    const resetForm = (form, dataPressPhone) => {
        if (dataPressPhone.hasAttribute("dial")) {
            dataPressPhone.value = dataPressPhone.value
                .trim()
                .replace(dataPressPhone.getAttribute("dial").trim(), "");
        }

        form.querySelectorAll("[name],button").forEach(
            p => (p.readOnly = !p.readOnly)
        );

        form.querySelectorAll("[name],button").forEach(
            p => (p.disabled = !p.disabled)
        );
    };

    const showSecondOpinionSidebarErrors = errors => {
        const errorsTargetEl = $("#second-opinion-sidebar-alerts");

        Object.keys(errors).forEach(ix => {
            const entry = errors[ix][0];

            errorsTargetEl.append(`

                            <div class="alert-card">
                                <div class="alert warning-alert">
                                    <i class="info fa fa-exclamation-circle"></i>
                                    <h3>${entry}</h3>
                                    <i class="close fa fa-times"
                                        onclick="((e) => {e.parentElement.parentElement.style.opacity = 0;setTimeout(() => {e.parentElement.parentElement.remove();}, 250);})(this);"></i>
                                </div>
                            </div>
                        `);
        });
    };


    const internationalFormErrors = errors => {
        const errorsTargetEl = $("#international-form-alerts");

        Object.keys(errors).forEach(ix => {
            const entry = errors[ix][0];

            errorsTargetEl.append(`

                            <div class="alert-card">
                                <div class="alert warning-alert">
                                    <i class="info fa fa-exclamation-circle"></i>
                                    <h3>${entry}</h3>
                                    <i class="close fa fa-times"
                                        onclick="((e) => {e.parentElement.parentElement.style.opacity = 0;setTimeout(() => {e.parentElement.parentElement.remove();}, 250);})(this);"></i>
                                </div>
                            </div>
                        `);
        });
    };
    const showSecondOpinionHeaderErrors = errors => {
        const firstError = Object.hasOwnProperty(Object.keys(errors)[0]) ?
            errors[Object.keys(errors)[0]][0] :
            null;

        if (firstError) {
            Toast.fire({
                icon: "warning",
                title: firstError
            });
        }
    };

    const handleSecondOpinionSubmission = async(e, showErrors) => {

        grecaptcha.ready(async() => {
            let form = e.target.closest("form");
            const eventName = form.getAttribute("id").toSnake();
            let dataPressPhone = form.querySelector("input[data-press-phone]");
            let dataPressEmail = form.querySelector("input[data-press-email]");
            let dataTimeToCall = form.querySelector(
                'select[name="time-to-call"]'
            );

            if (dataPressEmail) {
                if (!(dataPressEmail.value.length > 0)) {
                    Toast.fire({
                        icon: "warning",
                        title: "Please provide a valid email!"
                    });
                    dataPressEmail.focus();
                    dataPressEmail.classList.add("is-invalid");
                    return false;
                }
            }

            let phone_dial = '';
            if(dataPressPhone.hasAttribute("dial")) {
                phone_dial = dataPressPhone.getAttribute("dial").trim();
            }

            if (phone_dial == '+91' && dataPressPhone.value.length != 10) {
                Toast.fire({
                    icon: "warning",
                    title: "Please provide a valid number!"
                });
                dataPressPhone.focus();
                dataPressPhone.classList.add("is-invalid");
                return false;
            }else {
                dataPressPhone.classList.remove("is-invalid");
            }


            if (
                dataPressPhone.value.length < 5 ||
                dataPressPhone.value.length > 14 ||
                dataPressPhone.value.charAt(0) === '0'
            ) {
                Toast.fire({
                    icon: "warning",
                    title: "Please provide a valid number!"
                });
                dataPressPhone.focus();
                dataPressPhone.classList.add("is-invalid");
                return false;
            }
            if (!(dataTimeToCall.value.length > 0)) {
                Toast.fire({
                    icon: "warning",
                    title: "Please provide a preferred time to talk!"
                });
                dataTimeToCall.classList.add("is-invalid");
                return false;
            }


            if (form.checkValidity()) {
                form.querySelectorAll("[name],button").forEach(
                    p => (p.readOnly = !p.readOnly)
                );

                if (dataPressPhone.hasAttribute("dial")) {
                    dataPressPhone.value =
                        // dataPressPhone.getAttribute("dial").trim() +
                        dataPressPhone.value.trim();
                }



            //    return;

                let fd = new FormData(form);

                let fileObject = fd.get('file');

                let fileName = fileObject.name;

                // const recaptcha = await recaptchaInit(eventName);

                // fd.append("recaptcha", recaptcha);

                dataPressPhone.value =
                // dataPressPhone.getAttribute("dial").trim() +
                dataPressPhone.value.trim();

                fd.delete("mobile");
                fd.append("mobile",dataPressPhone.getAttribute("dial").trim()+dataPressPhone.value);

                form.querySelectorAll("[name],button").forEach(
                    p => (p.disabled = !p.disabled)
                );
                $.ajaxSetup({
                    headers: {
                        "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr(
                            "content"
                        )
                    }
                });

                $.ajax({
                        url: form.getAttribute("action"),
                        type: "post",
                        data: fd,
                        cache: false,
                        contentType: false,
                        processData: false
                    })
                    .done(function(response) {
                        if (response.status == "success") {
                            $(form).html(
                                "<div class='second-opinion-submitted'>" +
                                " Second opinion is not a physical/video consultation, our health specialists will study your reports and will get back to you for further details." +
                                "</div>"
                            );
                            if (
                                document.getElementById(
                                    "second-opinion-sidebar-subtitle"
                                )
                            ) {
                                document

                                    .getElementById(
                                    "second-opinion-sidebar-subtitle"
                                )

                                .remove();
                            }


                            if (fileName != '') {
                                dataLayer.push({
                                    'event': 'Mhc_header_gaso_form_upload'
                                });
                            }

                            dataLayer.push({
                                'event': 'Mhc_header_gaso_form_submit'
                            });



                        } else if (response.status === "error") {
                            showErrors(response.errors);

                            resetForm(form, dataPressPhone);
                        } else {
                            $(form).append(
                                "<div class='color-white text-center f-18'>" +
                                "Submission failed. Please try after sometime." +
                                "</div>"
                            );
                            if (
                                document.getElementById(
                                    "second-opinion-sidebar-subtitle"
                                )
                            ) {
                                document

                                    .getElementById(
                                    "second-opinion-sidebar-subtitle"
                                )

                                .remove();
                            }

                            console.error(response);
                        }
                    })
                    .fail(function(error) {
                        console.error("error", error);
                        form.querySelectorAll("[name],button").forEach(
                            p => (p.readOnly = !p.readOnly)
                        );
                        form.querySelectorAll("[name],button").forEach(
                            p => (p.disabled = !p.disabled)
                        );
                    });
            } else {
                let tmpSubmit = document.createElement("button");
                form.appendChild(tmpSubmit);
                tmpSubmit.click();
                form.removeChild(tmpSubmit);
            }
        });
    };




    const handleSecondOpinionSidebarSubmission = async(e, showErrors) => {
        grecaptcha.ready(async() => {
            let form = e.target.closest("form");
            const eventName = form.getAttribute("id").toSnake();
            let dataPressPhone = form.querySelector("input[data-press-phone]");

            let phone_dial = '';
            if(dataPressPhone.hasAttribute("dial")) {
                phone_dial = dataPressPhone.getAttribute("dial").trim();
            }

            if (phone_dial == '+91' && dataPressPhone.value.length != 10) {
                Toast.fire({
                    icon: "warning",
                    title: "Please provide a valid number!"
                });
                dataPressPhone.focus();
                dataPressPhone.classList.add("is-invalid");
                return false;
            }else {
                dataPressPhone.classList.remove("is-invalid");
            }

            if (
                dataPressPhone.value.length < 5 ||
                dataPressPhone.value.length > 14 ||
                dataPressPhone.value.charAt(0) === '0'
            ) {
                Toast.fire({
                    icon: "warning",
                    title: "Please provide a valid number!"
                });
                dataPressPhone.focus();
                dataPressPhone.classList.add("is-invalid");
                return false;
            }

            if (form.checkValidity()) {
                form.querySelectorAll("[name],button").forEach(
                    p => (p.readOnly = !p.readOnly)
                );

                if (dataPressPhone.hasAttribute("dial")) {
                    dataPressPhone.value =
                        // dataPressPhone.getAttribute("dial").trim() +
                        dataPressPhone.value.trim();
                }

                let fd = new FormData(form);
                fd.delete("mobile");
                fd.append("mobile",dataPressPhone.getAttribute("dial").trim()+dataPressPhone.value);
                let fileObject = fd.get('file');
                let fileName = fileObject.name;

                let entityName = fd.get('entity_name').toLowerCase().split(' ').join('_');
                let entitytype = fd.get('entity_type');



                if (fileName != '') {
                    dataLayer.push({
                        'event': 'Mhc_gaso_form_upload',
                        'Mhc_var_gaso_form_upload': entitytype + '_' + entityName
                    });
                }


                dataLayer.push({
                    'event': 'Mhc_gaso_form_submit',
                    'Mhc_var_gaso_form_submit': entitytype + '_' + entityName
                });


                const recaptcha = await recaptchaInit(eventName);

                fd.append("recaptcha", recaptcha);

                form.querySelectorAll("[name],button").forEach(
                    p => (p.disabled = !p.disabled)
                );
                $.ajaxSetup({
                    headers: {
                        "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr(
                            "content"
                        )
                    }
                });

                $.ajax({
                        url: form.getAttribute("action"),
                        type: "post",
                        data: fd,
                        cache: false,
                        contentType: false,
                        processData: false
                    })
                    .done(function(response) {
                        if (response.status == "success") {
                            $(form).html(
                                "<div class='second-opinion-submitted'>" +
                                " Second opinion is not a physical/video consultation, our health specialists will study your reports and will get back to you for further details." +
                                "</div>"
                            );
                            if (
                                document.getElementById(
                                    "second-opinion-sidebar-subtitle"
                                )
                            ) {
                                document

                                    .getElementById(
                                    "second-opinion-sidebar-subtitle"
                                )

                                .remove();
                            }
                        } else if (response.status === "error") {
                            showErrors(response.errors);

                            resetForm(form, dataPressPhone);
                        } else {
                            $(form).append(
                                "<div class='color-white text-center f-18'>" +
                                "Submission failed. Please try after sometime." +
                                "</div>"
                            );
                            if (
                                document.getElementById(
                                    "second-opinion-sidebar-subtitle"
                                )
                            ) {
                                document

                                    .getElementById(
                                    "second-opinion-sidebar-subtitle"
                                )

                                .remove();
                            }

                            console.error(response);
                        }
                    })
                    .fail(function(error) {
                        console.error("error", error);
                        form.querySelectorAll("[name],button").forEach(
                            p => (p.readOnly = !p.readOnly)
                        );
                        form.querySelectorAll("[name],button").forEach(
                            p => (p.disabled = !p.disabled)
                        );
                    });
            } else {
                let tmpSubmit = document.createElement("button");
                form.appendChild(tmpSubmit);
                tmpSubmit.click();
                form.removeChild(tmpSubmit);
            }
        });
    };





    const internationalFormSubmission = async(e, showErrors) => {
        grecaptcha.ready(async() => {
            let form = e.target.closest("form");
            const eventName = form.getAttribute("id").toSnake();
            let dataPressPhone = form.querySelector("input[data-press-phone]");
            let dataPressName = form.querySelector("input[data-press-name]");
            let dataPressEmail = form.querySelector("input[data-press-email]");

            if (
                dataPressName.value.length < 2
            ) {
                Toast.fire({
                    icon: "warning",
                    title: "Please provide a valid name!"
                });
                dataPressName.focus();
                dataPressName.classList.add("is-invalid");
                return false;
            } else {
                dataPressName.classList.remove("is-invalid");
            }


            let phone_dial = '';
            if(dataPressPhone.hasAttribute("dial")) {
                phone_dial = dataPressPhone.getAttribute("dial").trim();
            }

            if (phone_dial == '+91' && dataPressPhone.value.length != 10) {
                Toast.fire({
                    icon: "warning",
                    title: "Please provide a valid number!"
                });
                dataPressPhone.focus();
                dataPressPhone.classList.add("is-invalid");
                return false;
            }else {
                dataPressPhone.classList.remove("is-invalid");
            }


            if (
                dataPressPhone.value.length < 5 ||
                dataPressPhone.value.length > 14||
                dataPressPhone.value.charAt(0) === '0'
            ) {
                Toast.fire({
                    icon: "warning",
                    title: "Please provide a valid number!"
                });
                dataPressPhone.focus();
                dataPressPhone.classList.add("is-invalid");
                return false;
            } else {
                dataPressPhone.classList.remove("is-invalid");
            }

            if (form.checkValidity()) {
                form.querySelectorAll("[name],button").forEach(
                    p => (p.readOnly = !p.readOnly)
                );

                if (dataPressPhone.hasAttribute("dial")) {
                    dataPressPhone.value =
                        dataPressPhone.getAttribute("dial").trim() +
                        dataPressPhone.value.trim();
                }

                let fd = new FormData(form);

                const recaptcha = await recaptchaInit(eventName);

                fd.append("recaptcha", recaptcha);

                form.querySelectorAll("[name],button").forEach(
                    p => (p.disabled = !p.disabled)
                );
                $.ajaxSetup({
                    headers: {
                        "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr(
                            "content"
                        )
                    }
                });

                $.ajax({
                        url: form.getAttribute("action"),
                        type: "post",
                        data: fd,
                        cache: false,
                        contentType: false,
                        processData: false
                    })
                    .done(function(response) {
                        if (response.status == "success") {

                            $(form).html(
                                "<div class='second-opinion-submitted color-blue pl-lg-4'>" +
                                " Thank you for submitting your details. One of our health experts will reach out to you shortly." +
                                "</div>"
                            );
                            if (
                                document.getElementById(
                                    "international-title"
                                )
                            ) {
                                document

                                    .getElementById(
                                    "international-title"
                                )

                                .innerHTML = "";
                            }
                        } else if (response.status === "error") {
                            showErrors(response.errors);

                            resetForm(form, dataPressPhone);
                        } else {
                            $(form).append(
                                "<div class='color-white text-center f-18'>" +
                                "Submission failed. Please try after sometime." +
                                "</div>"
                            );
                            if (
                                document.getElementById(
                                    "second-opinion-sidebar-subtitle"
                                )
                            ) {
                                document

                                    .getElementById(
                                    "second-opinion-sidebar-subtitle"
                                )

                                .remove();
                            }

                            console.error(response);
                        }
                    })
                    .fail(function(error) {
                        console.error("error", error);
                        form.querySelectorAll("[name],button").forEach(
                            p => (p.readOnly = !p.readOnly)
                        );
                        form.querySelectorAll("[name],button").forEach(
                            p => (p.disabled = !p.disabled)
                        );
                    });
            } else {
                let tmpSubmit = document.createElement("button");
                form.appendChild(tmpSubmit);
                tmpSubmit.click();
                form.removeChild(tmpSubmit);
            }
        });
    };



    const handleSecondOpinionBlogSidebarSubmission = async(e, showErrors) => {
        grecaptcha.ready(async() => {
            let form = e.target.closest("form");
            const eventName = form.getAttribute("id").toSnake();
            let dataPressPhone = form.querySelector("input[data-press-phone]");

            let phone_dial = '';
            if(dataPressPhone.hasAttribute("dial")) {
                phone_dial = dataPressPhone.getAttribute("dial").trim();
            }

            if (phone_dial == '+91' && dataPressPhone.value.length != 10) {
                Toast.fire({
                    icon: "warning",
                    title: "Please provide a valid number!"
                });
                dataPressPhone.focus();
                dataPressPhone.classList.add("is-invalid");
                return false;
            }else {
                dataPressPhone.classList.remove("is-invalid");
            }

            if (
                dataPressPhone.value.length < 5 ||
                dataPressPhone.value.length > 14 ||
                dataPressPhone.value.charAt(0) === '0'
            ) {
                Toast.fire({
                    icon: "warning",
                    title: "Please provide a valid number!"
                });
                dataPressPhone.focus();
                dataPressPhone.classList.add("is-invalid");
                return false;
            }

            if (form.checkValidity()) {
                form.querySelectorAll("[name],button").forEach(
                    p => (p.readOnly = !p.readOnly)
                );

                if (dataPressPhone.hasAttribute("dial")) {
                    dataPressPhone.value =
                        dataPressPhone.getAttribute("dial").trim() +
                        dataPressPhone.value.trim();
                }

                let fd = new FormData(form);

                // const recaptcha = await recaptchaInit(eventName);

                // fd.append("recaptcha", recaptcha);

                form.querySelectorAll("[name],button").forEach(
                    p => (p.disabled = !p.disabled)
                );
                $.ajaxSetup({
                    headers: {
                        "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr(
                            "content"
                        )
                    }
                });

                $.ajax({
                        url: form.getAttribute("action"),
                        type: "post",
                        data: fd,
                        cache: false,
                        contentType: false,
                        processData: false
                    })
                    .done(function(response) {
                        if (response.status == "success") {
                            $(form).html(
                                "<div class='second-opinion-submitted'>" +
                                " Thank you for submitting your details. One of our health experts will reach out to you shortly." +
                                "</div>"
                            );
                            if (
                                document.getElementById(
                                    "second-opinion-sidebar-subtitle"
                                )
                            ) {
                                document

                                    .getElementById(
                                    "second-opinion-sidebar-subtitle"
                                )

                                .remove();
                            }
                        } else if (response.status === "error") {
                            showErrors(response.errors);

                            resetForm(form, dataPressPhone);
                        } else {
                            $(form).append(
                                "<div class='color-white text-center f-18'>" +
                                "Submission failed. Please try after sometime." +
                                "</div>"
                            );
                            if (
                                document.getElementById(
                                    "second-opinion-sidebar-subtitle"
                                )
                            ) {
                                document

                                    .getElementById(
                                    "second-opinion-sidebar-subtitle"
                                )

                                .remove();
                            }

                            console.error(response);
                        }
                    })
                    .fail(function(error) {
                        console.error("error", error);
                        form.querySelectorAll("[name],button").forEach(
                            p => (p.readOnly = !p.readOnly)
                        );
                        form.querySelectorAll("[name],button").forEach(
                            p => (p.disabled = !p.disabled)
                        );
                    });
            } else {
                let tmpSubmit = document.createElement("button");
                form.appendChild(tmpSubmit);
                tmpSubmit.click();
                form.removeChild(tmpSubmit);
            }
        });
    };



    const handleSecondOpinionBlogSidebarSubmissionNewBlog = async(e, showErrors) => {
        grecaptcha.ready(async() => {
            let form = e.target.closest("form");
            const eventName = form.getAttribute("id").toSnake();
            let dataPressName = form.querySelector("input[data-press-name]");
            let dataPressPhone = form.querySelector("input[data-press-phone]");
            let dataPressCountry = form.querySelector("input[data-press-country");
            let dataPressEmail = form.querySelector("input[data-press-email");
            
           


            if (
                dataPressName.value.length < 2
            ) {
                Toast.fire({
                    icon: "warning",
                    title: "Please provide a valid name!"
                });
                dataPressName.focus();
                dataPressName.classList.add("is-invalid");
                return false;
            }

          let phone_dial = '';
            if(dataPressPhone.hasAttribute("dial")) {
                phone_dial = dataPressPhone.getAttribute("dial").trim();
            }

            if (phone_dial == '+91' && dataPressPhone.value.length != 10) {
                Toast.fire({
                    icon: "warning",
                    title: "Please provide a valid number!"
                });
                dataPressPhone.focus();
                dataPressPhone.classList.add("is-invalid");
                return false;
            }else {
                dataPressPhone.classList.remove("is-invalid");
            }

            if (
                dataPressPhone.value.length < 5 ||
                dataPressPhone.value.length > 14 ||
                dataPressPhone.value.charAt(0) === '0'
            ) {
                Toast.fire({
                    icon: "warning",
                    title: "Please provide a valid number!"
                });
                dataPressPhone.focus();
                dataPressPhone.classList.add("is-invalid");
                return false;
            }

            let validRegex = '[a-z0-9]+@[a-z]+\.[a-z]{2,3}';

            if (!dataPressEmail.value.match(validRegex)) {
                Toast.fire({
                    icon: "warning",
                    title: "Please provide a valid email!"
                });
                try {
                    dataPressEmail.focus();
                } catch (e) {}
                dataPressEmail.classList.add("is-invalid");
                return false;
            }else {
                dataPressEmail.classList.remove("is-invalid");
            }

          
            if (form.checkValidity()) {
                form.querySelectorAll("[name],button").forEach(
                    p => (p.readOnly = !p.readOnly)
                );

                if (dataPressPhone.hasAttribute("dial")) {
                    dataPressPhone.value =
                        // dataPressPhone.getAttribute("dial").trim() +
                        dataPressPhone.value.trim();
                }

                let fd = new FormData(form);


                fd.delete("mobile");
                fd.append("mobile",dataPressPhone.getAttribute("dial").trim()+dataPressPhone.value);

                // const recaptcha = await recaptchaInit(eventName);

                // fd.append("recaptcha", recaptcha);

                form.querySelectorAll("[name],button").forEach(
                    p => (p.disabled = !p.disabled)
                );
                $.ajaxSetup({
                    headers: {
                        "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr(
                            "content"
                        )
                    }
                });

                $(form).prev().hide();
                $(form).html(
                    "<div id='oaderContainer' class='loader-container'>" +
                    "<div id='loader' class='loader'>" + "</div>" +
                    "<span>"+ "Loading..." +"</span>" +
                    "</div>"
                );

                    $.ajax({
                        url: form.getAttribute("action"),
                        type: "post",
                        data: fd,
                        cache: false,
                        contentType: false,
                        processData: false
                    })
                    .done(function(response) {
                        if (response.status == "success") {
                            $(form).html(
                                "<div class='second-opinion-submitted pb-1 color-gray-600'>" +
                                " Thank you for submitting your details. One of our health experts will reach out to you shortly." +
                                "</div>"
                            );
                            $(form).prev().hide();
                            if (
                                document.getElementById(
                                    "second-opinion-sidebar-subtitle"
                                )
                            ) {
                                document

                                    .getElementById(
                                    "second-opinion-sidebar-subtitle"
                                )

                                .remove();
                            }
                        } else if (response.status === "error") {
                            showErrors(response.errors);

                            resetForm(form, dataPressPhone);
                        } else {
                            $(form).append(
                                "<div class='color-white text-center f-18'>" +
                                "Submission failed. Please try after sometime." +
                                "</div>"
                            );
                            if (
                                document.getElementById(
                                    "second-opinion-sidebar-subtitle"
                                )
                            ) {
                                document

                                    .getElementById(
                                    "second-opinion-sidebar-subtitle"
                                )

                                .remove();
                            }

                            console.error(response);
                        }
                    })
                    .fail(function(error) {
                        console.error("error", error);
                        form.querySelectorAll("[name],button").forEach(
                            p => (p.readOnly = !p.readOnly)
                        );
                        form.querySelectorAll("[name],button").forEach(
                            p => (p.disabled = !p.disabled)
                        );
                    });
            } else {
                let tmpSubmit = document.createElement("button");
                form.appendChild(tmpSubmit);
                tmpSubmit.click();
                form.removeChild(tmpSubmit);
            }
        });
    };






    $("button#submitOpinionForm").on("click", async function(event) {
        event.preventDefault();

        await handleSecondOpinionSubmission(
            event,
            showSecondOpinionHeaderErrors
        );
    });

    $("button#subscribe-btn").on("click", async function(e) {
        e.preventDefault();
        e.stopImmediatePropagation();

        grecaptcha.ready(async() => {
            let form = e.target.closest("form#subscribe-newsletter");
            const eventName = form.getAttribute("id").toSnake();
            if (form.checkValidity()) {
                let fd = new FormData(form);

                const recaptcha = await recaptchaInit(eventName);

                fd.append("recaptcha", recaptcha);



                form.querySelectorAll("[name],button").forEach(
                    p => (p.disabled = !p.disabled)
                );

                $.ajaxSetup({
                    headers: {
                        "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr(
                            "content"
                        )
                    }
                });
                $.ajax({
                        url: form.getAttribute("action"),
                        type: "post",
                        data: fd,
                        cache: false,
                        contentType: false,
                        processData: false
                    })
                    .done(function(response) {
                        if (response.status == "success") {
                            $(form).html(
                                "<div class='color-white text-center text-info font-weight-bold f-18'>" +
                                "Thank you for subscribing to Max Healthcare newsletter." +
                                "</div>"
                            );

                            dataLayer.push({
                                'event': 'Mhc_email_subscribe_form'
                            });

                        } else {
                            console.error(response);

                            $(form).append(
                                "<div class='color-white text-center text-info f-18 font-weight-bold'>" +
                                "Submission failed. Please try after sometime." +
                                "</div>"
                            );
                        }
                    })
                    .fail(function(error) {
                        console.log("error", error);
                        form.querySelectorAll("[name],button").forEach(
                            p => (p.disabled = !p.disabled)
                        );
                    });
            } else {
                let tmpSubmit = document.createElement("button");
                form.appendChild(tmpSubmit);
                tmpSubmit.click();
                form.removeChild(tmpSubmit);
            }
        });
    });

    $("button#submitOpinionFormSidebar").on("click", async function(event) {
        event.preventDefault();

        await handleSecondOpinionSidebarSubmission(
            event,
            showSecondOpinionSidebarErrors
        );
    });

    $("button#submitOpinionBlogFormSidebar").on("click", async function(event) {
        event.preventDefault();

        await handleSecondOpinionBlogSidebarSubmission(
            event,
            showSecondOpinionSidebarErrors
        );
    });

    $("button#submitOpinionBlogFormSidebarNewBlog").on("click", async function(event) {
        event.preventDefault();

        await handleSecondOpinionBlogSidebarSubmissionNewBlog(
            event,
            showSecondOpinionSidebarErrors
        );
    });

    $("button#internationalSubmit").on("click", async function(event) {
        event.preventDefault();

        await internationalFormSubmission(
            event,
            internationalFormErrors
        );
    });


    const $button1 = $('#unitFormSubmission');
    const $button4 = $('#unitFormSubmission2');
    const $button3 = $('#unitFormSubmission3');
   

    const $errorMsg6 = $('.error-msg6');
    const $errorMsg7 = $('.error-msg7');
    const $errorMsg8 = $('.error-msg8');

    const $checkbox1 = $('#agree2');
    const $checkbox3 = $('#agree3');
    const $checkbox4 = $('#agree4');

    // Initially disable the button
    $button1.prop('disabled', !$checkbox1.is(':checked'));
    $button3.prop('disabled', !$checkbox3.is(':checked'));
    $button4.prop('disabled', !$checkbox4.is(':checked'));

    $checkbox1.change(function() {
    $button1.prop('disabled', !$(this).is(':checked'));
        if ($(this).is(':checked')) {
            $errorMsg6.hide();
        } else {
            $errorMsg6.show();
        }
    });

 
    $checkbox3.change(function() {
        $button3.prop('disabled', !$(this).is(':checked'));
            if ($(this).is(':checked')) {
                $errorMsg7.hide();
            } else {
                $errorMsg7.show();
            }
        });
    $checkbox4.change(function() {
    $button4.prop('disabled', !$(this).is(':checked'));
        if ($(this).is(':checked')) {
            $errorMsg8.hide();
        } else {
            $errorMsg8.show();
        }
    });

    $("button.unitFormSubmission").on("click", async function(event) {
        event.preventDefault();

        grecaptcha.ready(async() => {
                let form = event.target.closest("form");
                const eventName = form.getAttribute("id").toSnake();
                let dataPressPhone = form.querySelector("input[data-press-phone]");
                let dataPressName = form.querySelector("input[data-press-name]");
                let dataPressEmail = form.querySelector("input[data-press-email]");
                let entity_type = form.querySelector("input[entity-type]");


                if (
                    dataPressName.value.length < 2
                ) {
                    Toast.fire({
                        icon: "warning",
                        title: "Please provide a valid name!"
                    });
                    dataPressName.focus();
                    dataPressName.classList.add("is-invalid");
                    return false;
                } else {
                    dataPressName.classList.remove("is-invalid");
                }


                let phone_dial = '';
                if(dataPressPhone.hasAttribute("dial")) {
                    phone_dial = dataPressPhone.getAttribute("dial").trim();
                }
    
                if (phone_dial == '+91' && dataPressPhone.value.length != 10) {
                    Toast.fire({
                        icon: "warning",
                        title: "Please provide a valid number!"
                    });
                    dataPressPhone.focus();
                    dataPressPhone.classList.add("is-invalid");
                    return false;
                }else {
                    dataPressPhone.classList.remove("is-invalid");
                }
    
    
                if (
                    dataPressPhone.value.length < 5 ||
                    dataPressPhone.value.length > 14 ||
                    dataPressPhone.value.charAt(0) === '0'
                ) {
                    Toast.fire({
                        icon: "warning",
                        title: "Please provide a valid number!"
                    });
                    dataPressPhone.focus();
                    dataPressPhone.classList.add("is-invalid");
                    return false;
                }
                // if (!(dataTimeToCall.value.length > 0)) {
                //     Toast.fire({
                //         icon: "warning",
                //         title: "Please provide a preferred time to talk!"
                //     });
                //     dataTimeToCall.classList.add("is-invalid");
                //     return false;
                // }


                // if (!(dataPressEmail.value.length > 0)) {
                //     Toast.fire({
                //         icon: "warning",
                //         title: "Please provide a valid email!"
                //     });
                //     try {
                //         dataPressEmail.focus();
                //     } catch (e) {}
                //     dataPressEmail.classList.add("is-invalid");
                //     return false;
                // } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(dataPressEmail.value)) {

                //     let checkOut = !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(dataPressEmail.value);
                //     console.log("chekcouttttt", checkOut);
                //     Toast.fire({
                //         icon: "warning",
                //         title: "Please provide a valid email!"
                //     });
                //     dataPressEmail.focus();
                //     dataPressEmail.classList.add("is-invalid");
                //     return false;
                // } else {
                //     dataPressEmail.classList.remove("is-invalid");
                // }



                if (form.checkValidity()) {

                    form.querySelectorAll("[name],button").forEach(
                        p => (p.readOnly = !p.readOnly)
                    );

                    if (dataPressPhone.hasAttribute("dial")) {
                        dataPressPhone.value =
                            // dataPressPhone.getAttribute("dial").trim() +
                            dataPressPhone.value.trim();
                    }



                    if (entity_type.value == 'Doctor Second Opinion') {
                        dataLayer.push({
                            'event': 'cps_so_form_banner_racb'
                        });
                    }

                    if (entity_type.value == 'Doctor Unit Page') {
                        dataLayer.push({
                            'event': 'cps_hos_form_banner_racb'
                        });

                    }




                    let fd = new FormData(form);

                    fd.delete("mobile");
                    fd.append("mobile",dataPressPhone.getAttribute("dial").trim()+dataPressPhone.value);

                    // const recaptcha = await recaptchaInit(eventName);

                    // fd.append("recaptcha", recaptcha);

                    form.querySelectorAll("[name],button").forEach(
                        p => (p.disabled = !p.disabled)
                    );

                    $.ajaxSetup({
                        headers: {
                            "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr(
                                "content"
                            )
                        }
                    });


                    $(form).prev().hide();
                    $(form).html(
                        "<div id='oaderContainer' class='loader-container'>" +
                        "<div id='loader' class='loader'>" + "</div>" +
                        "<span>"+ "Loading..." +"</span>" +
                        "</div>"

                    );

                    $.ajax({
                            url: form.getAttribute("action"),
                            type: "post",
                            data: fd,
                            cache: false,
                            contentType: false,
                            processData: false
                        })
                        .done(function(response) {

                            if (response.status == "success") {
                                $(form).html(
                                    "<div class='second-opinion-submitted pb-1 text-white'>" +
                                    " Thank you for submitting your details. One of our health experts will reach out to you shortly." +
                                    "</div>"
                                );
                                $(form).prev().hide();

                            } else if (response.status === "error") {
                                showErrors(response.errors);

                                resetForm(form, dataPressPhone);
                            } else {
                                $(form).append(
                                    "<div class='color-white text-center f-18'>" +
                                    "Submission failed. Please try after sometime." +
                                    "</div>"
                                );
                                $(form).prev().hide();

                                console.error(response);
                            }
                        })
                        .fail(function(error) {
                            console.error("error", error);
                            form.querySelectorAll("[name],button").forEach(
                                p => (p.readOnly = !p.readOnly)
                            );
                            form.querySelectorAll("[name],button").forEach(
                                p => (p.disabled = !p.disabled)
                            );
                        });
                } else {

                    let tmpSubmit = document.createElement("button");
                    form.appendChild(tmpSubmit);
                    tmpSubmit.click();
                    form.removeChild(tmpSubmit);
                }
            }

        );
    });



      $("button.unitFormSubmission1").on("click", async function(event) {
        event.preventDefault();

        grecaptcha.ready(async() => {
                let form = event.target.closest("form");
                const eventName = form.getAttribute("id").toSnake();
                let dataPressPhone = form.querySelector("input[data-press-phone]");
                let dataPressName = form.querySelector("input[data-press-name]");
                //let dataPressEmail = form.querySelector("input[data-press-email]");
                let entity_type = form.querySelector("input[entity-type]");


                let dataPressCountry = form.querySelector("input[data-press-country");



                let phone_dial = '';
                if(dataPressPhone.hasAttribute("dial")) {
                    phone_dial = dataPressPhone.getAttribute("dial").trim();
                }
    
                if (phone_dial == '+91' && dataPressPhone.value.length != 10) {
                    Toast.fire({
                        icon: "warning",
                        title: "Please provide a valid number!"
                    });
                    dataPressPhone.focus();
                    dataPressPhone.classList.add("is-invalid");
                    return false;
                }else {
                    dataPressPhone.classList.remove("is-invalid");
                }


                if (
                    dataPressPhone.value.length < 5 ||
                    dataPressPhone.value.length > 14 ||
                    dataPressPhone.value.charAt(0) === '0'
                ) {
                    Toast.fire({
                        icon: "warning",
                        title: "Please provide a valid number!"
                    });
                    dataPressPhone.focus();
                    dataPressPhone.classList.add("is-invalid");
                    return false;
                } else {
                    dataPressPhone.classList.contains("is-invalid") ?
                        dataPressPhone.classList.remove("is-invalid") :
                        "";
                }

             



                if (
                    dataPressName.value.length < 2
                ) {
                    Toast.fire({
                        icon: "warning",
                        title: "Please provide a valid name!"
                    });
                    dataPressName.focus();
                    dataPressName.classList.add("is-invalid");
                    return false;
                } else {
                    dataPressName.classList.remove("is-invalid");
                }


                /*
                                if (!(dataPressEmail.value.length > 0)) {
                                    Toast.fire({
                                        icon: "warning",
                                        title: "Please provide a valid email!"
                                    });
                                    try {
                                        dataPressEmail.focus();
                                    } catch (e) {}
                                    dataPressEmail.classList.add("is-invalid");
                                    return false;
                                } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(dataPressEmail.value)) {

                                    let checkOut = !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(dataPressEmail.value);
                                    console.log("chekcouttttt", checkOut);
                                    Toast.fire({
                                        icon: "warning",
                                        title: "Please provide a valid email!"
                                    });
                                    dataPressEmail.focus();
                                    dataPressEmail.classList.add("is-invalid");
                                    return false;
                                } else {
                                    dataPressEmail.classList.remove("is-invalid");
                                }

                */



                if (form.checkValidity()) {

                    form.querySelectorAll("[name],button").forEach(
                        p => (p.readOnly = !p.readOnly)
                    );

                    if (dataPressPhone.hasAttribute("dial")) {
                        dataPressPhone.value =
                            // dataPressPhone.getAttribute("dial").trim() +
                            dataPressPhone.value.trim();
                    }



                    if (entity_type.value == 'Doctor Second Opinion') {
                        dataLayer.push({
                            'event': 'cps_so_form_banner_racb'
                        });
                    }

                    if (entity_type.value == 'Doctor Unit Page') {
                        dataLayer.push({
                            'event': 'cps_hos_form_banner_racb'
                        });

                    }




                    let fd = new FormData(form);

                    fd.delete("mobile");
                    fd.append("mobile",dataPressPhone.getAttribute("dial").trim()+dataPressPhone.value);

                    // const recaptcha = await recaptchaInit(eventName);

                    // fd.append("recaptcha", recaptcha);

                    form.querySelectorAll("[name],button").forEach(
                        p => (p.disabled = !p.disabled)
                    );

                    $.ajaxSetup({
                        headers: {
                            "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr(
                                "content"
                            )
                        }
                    });

                    $(form).prev().hide();
                    $(form).html(
                        "<div id='oaderContainer' class='loader-container'>" +
                        "<div id='loader' class='loader'>" + "</div>" +
                        "<span>"+ "Loading..." +"</span>" +
                        "</div>"

                    );

                    $.ajax({
                            url: form.getAttribute("action"),
                            type: "post",
                            data: fd,
                            cache: false,
                            contentType: false,
                            processData: false
                        })
                        .done(function(response) {

                            if (response.status == "success") {
                                $(form).html(
                                    "<div class='second-opinion-submitted black pb-1'>" +
                                    " Thank you for submitting your details. One of our health experts will reach out to you shortly." +
                                    "</div>"
                                );
                                $(form).prev().hide();

                            } else if (response.status === "error") {
                                showErrors(response.errors);

                                resetForm(form, dataPressPhone);
                            } else {
                                $(form).append(
                                    "<div class='color-white text-center f-18'>" +
                                    "Submission failed. Please try after sometime." +
                                    "</div>"
                                );
                                $(form).prev().hide();

                                console.error(response);
                            }
                        })
                        .fail(function(error) {
                            console.error("error", error);
                            form.querySelectorAll("[name],button").forEach(
                                p => (p.readOnly = !p.readOnly)
                            );
                            form.querySelectorAll("[name],button").forEach(
                                p => (p.disabled = !p.disabled)
                            );
                        });
                } else {

                    let tmpSubmit = document.createElement("button");
                    form.appendChild(tmpSubmit);
                    tmpSubmit.click();
                    form.removeChild(tmpSubmit);
                }
            }

        );
    });



    $("button.unitFormSubmission2").on("click", async function(event) {
        event.preventDefault();

        grecaptcha.ready(async() => {
                let form = event.target.closest("form");
                const eventName = form.getAttribute("id").toSnake();
                let dataPressPhone = form.querySelector("input[data-press-phone]");
                let dataPressName = form.querySelector("input[data-press-name]");
                //let dataPressEmail = form.querySelector("input[data-press-email]");
                let entity_type = form.querySelector("input[entity-type]");
                let dataPressCountry = form.querySelector("input[data-press-country");


                let phone_dial = '';
                if(dataPressPhone.hasAttribute("dial")) {
                    phone_dial = dataPressPhone.getAttribute("dial").trim();
                }
    
                if (phone_dial == '+91' && dataPressPhone.value.length != 10) {
                    Toast.fire({
                        icon: "warning",
                        title: "Please provide a valid number!"
                    });
                    dataPressPhone.focus();
                    dataPressPhone.classList.add("is-invalid");
                    return false;
                }else {
                    dataPressPhone.classList.remove("is-invalid");
                }
                


                if (
                    dataPressPhone.value.length < 5 ||
                    dataPressPhone.value.length > 14 ||
                    dataPressPhone.value.charAt(0) === '0'
                ) {
                    Toast.fire({
                        icon: "warning",
                        title: "Please provide a valid number!"
                    });
                    dataPressPhone.focus();
                    dataPressPhone.classList.add("is-invalid");
                    return false;
                } else {
                    dataPressPhone.classList.contains("is-invalid") ?
                        dataPressPhone.classList.remove("is-invalid") :
                        "";
                }




                if (
                    dataPressName.value.length < 2
                ) {
                    Toast.fire({
                        icon: "warning",
                        title: "Please provide a valid name!"
                    });
                    dataPressName.focus();
                    dataPressName.classList.add("is-invalid");
                    return false;
                } else {
                    dataPressName.classList.remove("is-invalid");
                }


                




                if (form.checkValidity()) {

                    form.querySelectorAll("[name],button").forEach(
                        p => (p.readOnly = !p.readOnly)
                    );

                    if (dataPressPhone.hasAttribute("dial")) {
                        dataPressPhone.value =
                            // dataPressPhone.getAttribute("dial").trim() +
                            dataPressPhone.value.trim();
                    }



                    if (entity_type.value == 'Doctor Second Opinion') {
                        dataLayer.push({
                            'event': 'cps_so_form_banner_racb'
                        });
                    }

                    if (entity_type.value == 'Doctor Unit Page') {
                        dataLayer.push({
                            'event': 'cps_hos_form_banner_racb'
                        });

                    }




                    let fd = new FormData(form);

                    fd.delete("mobile");
                    fd.append("mobile",dataPressPhone.getAttribute("dial").trim()+dataPressPhone.value);

                    // const recaptcha = await recaptchaInit(eventName);

                    // fd.append("recaptcha", recaptcha);

                    form.querySelectorAll("[name],button").forEach(
                        p => (p.disabled = !p.disabled)
                    );

                    $.ajaxSetup({
                        headers: {
                            "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr(
                                "content"
                            )
                        }
                    });

                    $(form).prev().hide();
                    $(form).html(
                        "<div id='oaderContainer' class='loader-container'>" +
                        "<div id='loader' class='loader'>" + "</div>" +
                        "<span>"+ "Loading..." +"</span>" +
                        "</div>"

                    );

                    $.ajax({
                            url: form.getAttribute("action"),
                            type: "post",
                            data: fd,
                            cache: false,
                            contentType: false,
                            processData: false
                        })
                        .done(function(response) {

                            if (response.status == "success") {
                                $(form).html(
                                    "<div class='row align-items-center'>"+
                                    "<div class='col-lg-4 d-none d-lg-block'>"+
                                         "<img src='https://max-website20-images.s3.ap-south-1.amazonaws.com/cal_img_c04fc9f0bc.png' class='img-fluid' alt=''>"+
                                     "</div>"+
                                     "<div class='col-lg-8'>"+
                                          "<div class=''>"+
                                             "<div class='thank-message d-flex flex-column align-items-center justify-content-center'>"+
                                             "  <h3 class='color-blue  f-lg-20 f-20 font-weight-bold mb-3 text-center'><span class='text-lgreen'>Treatment </span>Cost Calculator</h3>"+
                                             "<img src='https://max-website20-images.s3.ap-south-1.amazonaws.com/thank_you_img_88938e1a63.png' class='img-fluid' alt=''>"+
                                             "<p class='mb-2 font-weight-bold f-lg-26 color-blue'>Thank You</p>"+
                                             "<p class='f-lg-16 text-center mb-0'>Our health advisors will get back to you.</p>"+
                                             "</div>"+
                                           "</div>"+
                                    "</div>"+
                                   "</div>"
                                );
                                $(form).prev().hide();


                            } else if (response.status === "error") {
                                showErrors(response.errors);

                                resetForm(form, dataPressPhone);
                            } else {
                                $(form).append(
                                    "<div class='color-white text-center f-18'>" +
                                    "Submission failed. Please try after sometime." +
                                    "</div>"
                                );
                                $(form).prev().hide();

                                console.error(response);
                            }
                        })
                        .fail(function(error) {
                            console.error("error", error);
                            form.querySelectorAll("[name],button").forEach(
                                p => (p.readOnly = !p.readOnly)
                            );
                            form.querySelectorAll("[name],button").forEach(
                                p => (p.disabled = !p.disabled)
                            );
                        });
                } else {

                    let tmpSubmit = document.createElement("button");
                    form.appendChild(tmpSubmit);
                    tmpSubmit.click();
                    form.removeChild(tmpSubmit);
                }
            }

        );
    });




    const $Intbutton1 = $('#intcontactSubmit1');
    const $contactSubmissionCommon = $('.contactSubmissionCommon');
    const $Intbutton2 = $('#intcontactSubmit2');
    const $Intbutton3 = $('#intcontactSubmit3');
    const $Intbutton4 = $('#intcontactSubmit4');
    const $Intbutton5 = $('#intcontactSubmit5');

    const $Intcheckbox1 = $('#agreeInt1');
    const $Intcheckbox2 = $('#agreeInt2');
    const $agreeCommon = $('#agreeCommon');
    const $Intcheckbox3 = $('#agreeIntDr1');
    const $Intcheckbox5 = $('#agreeIntDr');
    const $Intcheckbox4 = $('#agreeIntDr2');

    const $errorMsg1 = $('.error-msg');
    const $commonErrorMsg = $('.common-error-msg');

    const $errorMsg3 = $('.error-msg3');
    const $errorMsg4 = $('.error-msg4');
    const $errorMsg5 = $('.error-msg5');


    $Intbutton1.prop('disabled', !$Intcheckbox1.is(':checked'));
    $Intbutton2.prop('disabled', !$Intcheckbox2.is(':checked'));
    $contactSubmissionCommon.prop('disabled', !$agreeCommon.is(':checked'));
    $Intbutton3.prop('disabled', !$Intcheckbox3.is(':checked'));
    $Intbutton5.prop('disabled', !$Intcheckbox5.is(':checked'));
    $Intbutton4.prop('disabled', !$Intcheckbox4.is(':checked'));

    $agreeCommon.change(function() {
        $contactSubmissionCommon.prop('disabled', !$(this).is(':checked'));
            if ($(this).is(':checked')) {
                $commonErrorMsg.hide();
            } else {
                $commonErrorMsg.show();
            }
        });

    $Intcheckbox1.change(function() {
    $Intbutton1.prop('disabled', !$(this).is(':checked'));
        if ($(this).is(':checked')) {
            $errorMsg1.hide();
        } else {
            $errorMsg1.show();
        }
    });
    $Intcheckbox2.change(function() {
    $Intbutton2.prop('disabled', !$(this).is(':checked'));
        if ($(this).is(':checked')) {
            $errorMsg2.hide();
        } else {
            $errorMsg2.show();
        }
    });
    $Intcheckbox3.change(function() {
        $Intbutton3.prop('disabled', !$(this).is(':checked'));
        if ($(this).is(':checked')) {
            $errorMsg4.hide();
        } else {
            $errorMsg4.show();
        }
    });
    $Intcheckbox5.change(function() {
    $Intbutton5.prop('disabled', !$(this).is(':checked'));
    if ($(this).is(':checked')) {
        $errorMsg3.hide();
    } else {
        $errorMsg3.show();
    }
    });
    $Intcheckbox4.change(function() {
    $Intbutton4.prop('disabled', !$(this).is(':checked'));
        if ($(this).is(':checked')) {
            $errorMsg5.hide();
        } else {
            $errorMsg5.show();
        }
    });


    
    $("button.unitFormSubmission2").on("click", async function(event) {
        event.preventDefault();

        grecaptcha.ready(async() => {
                let form = event.target.closest("form");
                const eventName = form.getAttribute("id").toSnake();
                let dataPressPhone = form.querySelector("input[data-press-phone]");
                let dataPressName = form.querySelector("input[data-press-name]");
                //let dataPressEmail = form.querySelector("input[data-press-email]");
                let entity_type = form.querySelector("input[entity-type]");
                let dataPressCountry = form.querySelector("input[data-press-country");


                let phone_dial = '';
                if(dataPressPhone.hasAttribute("dial")) {
                    phone_dial = dataPressPhone.getAttribute("dial").trim();
                }
    
                if (phone_dial == '+91' && dataPressPhone.value.length != 10) {
                    Toast.fire({
                        icon: "warning",
                        title: "Please provide a valid number!"
                    });
                    dataPressPhone.focus();
                    dataPressPhone.classList.add("is-invalid");
                    return false;
                }else {
                    dataPressPhone.classList.remove("is-invalid");
                }
                


                if (
                    dataPressPhone.value.length < 5 ||
                    dataPressPhone.value.length > 14 ||
                    dataPressPhone.value.charAt(0) === '0'
                ) {
                    Toast.fire({
                        icon: "warning",
                        title: "Please provide a valid number!"
                    });
                    dataPressPhone.focus();
                    dataPressPhone.classList.add("is-invalid");
                    return false;
                } else {
                    dataPressPhone.classList.contains("is-invalid") ?
                        dataPressPhone.classList.remove("is-invalid") :
                        "";
                }




                if (
                    dataPressName.value.length < 2
                ) {
                    Toast.fire({
                        icon: "warning",
                        title: "Please provide a valid name!"
                    });
                    dataPressName.focus();
                    dataPressName.classList.add("is-invalid");
                    return false;
                } else {
                    dataPressName.classList.remove("is-invalid");
                }


                




                if (form.checkValidity()) {

                    form.querySelectorAll("[name],button").forEach(
                        p => (p.readOnly = !p.readOnly)
                    );

                    if (dataPressPhone.hasAttribute("dial")) {
                        dataPressPhone.value =
                            // dataPressPhone.getAttribute("dial").trim() +
                            dataPressPhone.value.trim();
                    }



                    if (entity_type.value == 'Doctor Second Opinion') {
                        dataLayer.push({
                            'event': 'cps_so_form_banner_racb'
                        });
                    }

                    if (entity_type.value == 'Doctor Unit Page') {
                        dataLayer.push({
                            'event': 'cps_hos_form_banner_racb'
                        });

                    }




                    let fd = new FormData(form);

                    fd.delete("mobile");
                    fd.append("mobile",dataPressPhone.getAttribute("dial").trim()+dataPressPhone.value);

                    // const recaptcha = await recaptchaInit(eventName);

                    // fd.append("recaptcha", recaptcha);

                    form.querySelectorAll("[name],button").forEach(
                        p => (p.disabled = !p.disabled)
                    );

                    $.ajaxSetup({
                        headers: {
                            "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr(
                                "content"
                            )
                        }
                    });

                    $(form).prev().hide();
                    $(form).html(
                        "<div id='oaderContainer' class='loader-container'>" +
                        "<div id='loader' class='loader'>" + "</div>" +
                        "<span>"+ "Loading..." +"</span>" +
                        "</div>"

                    );

                    $.ajax({
                            url: form.getAttribute("action"),
                            type: "post",
                            data: fd,
                            cache: false,
                            contentType: false,
                            processData: false
                        })
                        .done(function(response) {

                            if (response.status == "success") {
                                $(form).html(
                                    "<div class='row align-items-center'>"+
                                    "<div class='col-lg-4 d-none d-lg-block'>"+
                                         "<img src='https://max-website20-images.s3.ap-south-1.amazonaws.com/cal_img_c04fc9f0bc.png' class='img-fluid' alt=''>"+
                                     "</div>"+
                                     "<div class='col-lg-8'>"+
                                          "<div class=''>"+
                                             "<div class='thank-message d-flex flex-column align-items-center justify-content-center'>"+
                                             "  <h3 class='color-blue  f-lg-20 f-20 font-weight-bold mb-3 text-center'><span class='text-lgreen'>Treatment </span>Cost Calculator</h3>"+
                                             "<img src='https://max-website20-images.s3.ap-south-1.amazonaws.com/thank_you_img_88938e1a63.png' class='img-fluid' alt=''>"+
                                             "<p class='mb-2 font-weight-bold f-lg-26 color-blue'>Thank You</p>"+
                                             "<p class='f-lg-16 text-center mb-0'>Our health advisors will get back to you.</p>"+
                                             "</div>"+
                                           "</div>"+
                                    "</div>"+
                                   "</div>"
                                );
                                $(form).prev().hide();


                            } else if (response.status === "error") {
                                showErrors(response.errors);

                                resetForm(form, dataPressPhone);
                            } else {
                                $(form).append(
                                    "<div class='color-white text-center f-18'>" +
                                    "Submission failed. Please try after sometime." +
                                    "</div>"
                                );
                                $(form).prev().hide();

                                console.error(response);
                            }
                        })
                        .fail(function(error) {
                            console.error("error", error);
                            form.querySelectorAll("[name],button").forEach(
                                p => (p.readOnly = !p.readOnly)
                            );
                            form.querySelectorAll("[name],button").forEach(
                                p => (p.disabled = !p.disabled)
                            );
                        });
                } else {

                    let tmpSubmit = document.createElement("button");
                    form.appendChild(tmpSubmit);
                    tmpSubmit.click();
                    form.removeChild(tmpSubmit);
                }
            }

        );
    });




            $("button.intcontactSubmit").on("click", async function(event) {

                event.preventDefault();

                //grecaptcha.ready(async() => {


                let form = event.target.closest("form");

                const eventName = form.getAttribute("id").toSnake();

                let dataPressName = form.querySelector("input[data-press-name]");


                let dataPressPhone = form.querySelector("input[data-press-phone]");
                let dataPressEmail = form.querySelector("input[data-press-email");
                let dataPressCountry = form.querySelector("input[data-press-country");

                // console.log("dataPressPhone---",dataPressPhone,"===========", dataPressCountry.value);
                // alert(1);
                // return
                if (
                dataPressName.value.length < 2
                ) {
                Toast.fire({
                    icon: "warning",
                    title: "Please provide a valid name!"
                });
                dataPressName.focus();
                dataPressName.classList.add("is-invalid");
                return false;
                } else {
                dataPressName.classList.remove("is-invalid");
                }


                if (
                    dataPressPhone.value.length < 5 ||
                    dataPressPhone.value.length > 14 ||
                    dataPressPhone.value.charAt(0) === '0'
                ) {
                    Toast.fire({
                        icon: "warning",
                        title: "Please provide a valid number!"
                    });
                    dataPressPhone.focus();
                    dataPressPhone.classList.add("is-invalid");
                    return false;
                } else {
                    dataPressPhone.classList.contains("is-invalid") ?
                        dataPressPhone.classList.remove("is-invalid") :
                        "";
                }

                if (
                    dataPressCountry.value == 'India (भारत)' &&
                    dataPressPhone.value.length != 10
                    ) {
                    Toast.fire({
                        icon: "warning",
                        title: "Please provide a valid number!"
                    });
                    dataPressPhone.focus();
                    dataPressPhone.classList.add("is-invalid");
                    return false;
                    }else {
                        dataPressPhone.classList.remove("is-invalid");
                }


                if (!(dataPressEmail.value.length > 0)) {
                    Toast.fire({
                        icon: "warning",
                        title: "Please provide a valid email!"
                    });
                    try {
                        dataPressEmail.focus();
                    } catch (e) {}
                    dataPressEmail.classList.add("is-invalid");
                    return false;
                }


                let validRegex = '[a-z0-9]+@[a-z]+\.[a-z]{2,3}';

                    if (!dataPressEmail.value.match(validRegex)) {
                        Toast.fire({
                            icon: "warning",
                            title: "Please provide a valid email!"
                        });
                        try {
                            dataPressEmail.focus();
                        } catch (e) {}
                        dataPressEmail.classList.add("is-invalid");
                        return false;
                    }else {
                        dataPressEmail.classList.remove("is-invalid");
                    }



                    if (
                        dataPressCountry.value.length < 2
                        ) {
                        Toast.fire({
                            icon: "warning",
                            title: "Please select country!"
                        });
                            dataPressCountry.focus();
                            dataPressCountry.classList.add("is-invalid");
                            return false;
                        } else {
                            dataPressCountry.classList.remove("is-invalid");
                    }





                if (form.checkValidity()) {

                form.querySelectorAll("[name],button").forEach(
                    p => (p.readOnly = !p.readOnly)
                );

                if (dataPressPhone.hasAttribute("dial")) {
                    dataPressPhone.value =
                        dataPressPhone.getAttribute("dial").trim() +
                        dataPressPhone.value.trim();
                }



                //if (entity_type.value == 'Doctor Second Opinion') {



                let fd = new FormData(form);
                const recaptcha = await recaptchaInit(eventName);

                fd.append("recaptcha", recaptcha);

                // console.log("getclevertapId",clever.getclevertapId());
                
                clevertap.event.push("international_form", {
                    "Name": dataPressName.value,
                    "Phone": dataPressPhone.value,
                    "Email": dataPressEmail.value,            
                    "Country": dataPressCountry.value,
                    "URL": window.location.href
                });


                clevertap.onUserLogin.push({
                    "Site": {
                        "Phone": dataPressPhone.value,
                        "Email": dataPressEmail.value,
                        "Name": dataPressName.value
                    }
                });



                if (window.location.href.indexOf("/en/doctor/") > -1) {
                    clevertap.event.push("Intl-Doctor-profile", {
                        "URL": window.location.href,
                        "Doctor Name": $(".capture_head").text(),
                        "Form Submitted":true
                    });
                }



                if (window.location.href.indexOf("/international/en/contact-us") > -1) {
                    clevertap.event.push("Intl-Contact-us", {
                        "Referrer Page URL": document.referrer,
                        "Form Submitted":true
                    });
                }


                if (window.location.href.indexOf("/international/en/doctors") > -1) {
                    clevertap.event.push("Intl-Doctor-Page", {
                        "URL": window.location.href,
                        "Form Submitted":true
                    });
                }

                if (window.location.href.indexOf("/international/en/speciality/") > -1) {
                    clevertap.event.push("Intl-Specialities", {
                        "Speciality Page":$(".capture_head").text(),
                        "URL": window.location.href,
                        "Form Submitted":true
                    });
                }




                if (window.location.href.indexOf("/international/en/procedure/") > -1) {
                    clevertap.event.push("Intl-Procedures", {
                        "Procedure Page":$(".capture_head").text(),
                        "URL": window.location.href,
                        "Form Submitted":true
                    });
                }

                if (window.location.href.indexOf("/international/en/cost/") > -1) {
                    clevertap.event.push("Intl-Costs", {
                        "Treatment Name":$(".capture_head").text(),
                        "Additional Members": $("#additional_member").val()-1,
                        "Food Cost": $("#food_cost").val(),
                        "Hotel Cost": $("#hotel_cost").val(),
                        "URL": window.location.href,
                        "Form Submitted":true
                    });
                }


                form.querySelectorAll("[name],button").forEach(
                    p => (p.disabled = !p.disabled)
                );

                let full_url = window.location.href;
                fd.append("full_url", full_url);




                $.ajaxSetup({
                    headers: {
                        "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr(
                            "content"
                        )
                    }
                });




                $(form).prev().hide();
                $(form).html(
                    "<div id='oaderContainer' class='loader-container'>" +
                    "<div id='loader' class='loader'>" + "</div>" +
                    "<span>"+ "Loading..." +"</span>" +
                    "</div>"

                );


                $.ajax({
                        url: form.getAttribute("action"),
                        type: "post",
                        data: fd,
                        cache: false,
                        contentType: false,
                        processData: false
                    })
                    .done(function(response) {

                        if (response.status == "success") {

                            $(form).html(
                                "<div class='second-opinion-submitted pb-1 color-gray-600'>" +
                                " Thank you for submitting your details. One of our health experts will reach out to you shortly." +
                                "</div>"
                            );
                            $(form).prev().hide();

                        } else if (response.status === "error") {
                            showErrors(response.errors);

                            resetForm(form, dataPressPhone);
                        } else {
                            $(form).append(
                                "<div class='color-white text-center f-18'>" +
                                "Submission failed. Please try after sometime." +
                                "</div>"
                            );
                            $(form).prev().hide();

                            console.error(response);
                        }
                    })
                    .fail(function(error) {
                        console.error("error", error);
                        form.querySelectorAll("[name],button").forEach(
                            p => (p.readOnly = !p.readOnly)
                        );
                        form.querySelectorAll("[name],button").forEach(
                            p => (p.disabled = !p.disabled)
                        );
                    });
                } else {

                let tmpSubmit = document.createElement("button");
                form.appendChild(tmpSubmit);
                tmpSubmit.click();
                form.removeChild(tmpSubmit);
                }
                //});
            });

    $("button.requestCallbackFormSubmission").on("click", async function(event) {

        event.preventDefault();

        grecaptcha.ready(async() => {


            let form = event.target.closest("form");

            const eventName = form.getAttribute("id").toSnake();
            let dataPressPhone = form.querySelector("input[data-press-phone]");
            let dataPressName = form.querySelector("input[data-press-name]");
            let entity_type = form.querySelector("input[entity-type]");
            let full_url = form.querySelector("input[current-url]");



            if (
                dataPressName.value.length < 2
            ) {
                Toast.fire({
                    icon: "warning",
                    title: "Please provide a valid name!"
                });
                dataPressName.focus();
                dataPressName.classList.add("is-invalid");
                return false;
            } else {
                dataPressName.classList.remove("is-invalid");
            }

            let phone_dial = '';
            if(dataPressPhone.hasAttribute("dial")) {
                phone_dial = dataPressPhone.getAttribute("dial").trim();
            }

            if (phone_dial == '+91' && dataPressPhone.value.length != 10) {
                Toast.fire({
                    icon: "warning",
                    title: "Please provide a valid number!"
                });
                dataPressPhone.focus();
                dataPressPhone.classList.add("is-invalid");
                return false;
            }else {
                dataPressPhone.classList.remove("is-invalid");
            }

            if (
                dataPressPhone.value.length < 5 ||
                dataPressPhone.value.length > 14 ||
                dataPressPhone.value.charAt(0) === '0'
            ) {
                Toast.fire({
                    icon: "warning",
                    title: "Please provide a valid number!"
                });
                dataPressPhone.focus();
                dataPressPhone.classList.add("is-invalid");
                return false;
            }




            if (form.checkValidity()) {

                form.querySelectorAll("[name],button").forEach(
                    p => (p.readOnly = !p.readOnly)
                );

                if (dataPressPhone.hasAttribute("dial")) {
                    dataPressPhone.value =
                        // dataPressPhone.getAttribute("dial").trim() +
                        dataPressPhone.value.trim();

                    dataPressPhone.value2 =
                        dataPressPhone.getAttribute("dial").trim() +
                        dataPressPhone.value.trim();
                }


                if (entity_type.value == 'Doctor Second Opinion') {
                    dataLayer.push({
                        'event': 'cps_so_form_racb',
                        'var_cps_so_form_racb': full_url.value
                    });
                }

                if (entity_type.value == 'Doctor CPS Page') {
                    dataLayer.push({
                        'event': 'cps_dr_request_a_call_back',
                        'var_cps_dr_request_a_call_back': full_url.value
                    });
                }

                if (entity_type.value == 'Doctor Unit Page') {
                    dataLayer.push({
                        'event': 'cps_hos_form_racb',
                        'var_cps_hos_form_racb': full_url.value
                    });
                }


                if (entity_type.value == 'Doctor Speciality Page') {

                    dataLayer.push({
                        'event': 'sil_request_a_call_back',
                        'var_sil_request_a_call_back': full_url.value
                    });
                }

                if (entity_type.value == 'Find a Doctor') {
                    console.log("full url", full_url);

                    dataLayer.push({
                        'event': 'fad_request_a_call_back',
                        'var_fad_request_a_call_back': full_url.value
                    });
                }


                let fd = new FormData(form);
                // const recaptcha = await recaptchaInit(eventName);

                //fd.append("recaptcha", recaptcha);

                fd.delete("mobile");
                fd.append("mobile",dataPressPhone.getAttribute("dial").trim()+dataPressPhone.value);

                form.querySelectorAll("[name],button").forEach(
                    p => (p.disabled = !p.disabled)
                );

                let urlParams = new URLSearchParams(window.location.href);
                let gclid_data = urlParams.get('gclid');

                clevertap.event.push("Get a Call Back", {
                    "Name": dataPressName.value,
                    "Phone": dataPressPhone.value2,
                    "Gclid": gclid_data,
                    "URL": window.location.href
                });

                console.log("GCLID", gclid_data);



                $.ajaxSetup({
                    headers: {
                        "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr(
                            "content"
                        )
                    }
                });

                $.ajax({
                        url: form.getAttribute("action"),
                        type: "post",
                        data: fd,
                        cache: false,
                        contentType: false,
                        processData: false
                    })
                    .done(function(response) {

                        if (response.status == "success") {
                            $(form).html(
                                "<div class='second-opinion-submitted pb-1 color-gray-600'>" +
                                " Thank you for submitting your details. One of our health experts will reach out to you shortly." +
                                "</div>"
                            );
                            $(form).prev().hide();

                        } else if (response.status === "error") {
                            showErrors(response.errors);

                            resetForm(form, dataPressPhone);
                        } else {
                            $(form).append(
                                "<div class='color-white text-center f-18'>" +
                                "Submission failed. Please try after sometime." +
                                "</div>"
                            );
                            $(form).prev().hide();

                            console.error(response);
                        }
                    })
                    .fail(function(error) {
                        console.error("error", error);
                        form.querySelectorAll("[name],button").forEach(
                            p => (p.readOnly = !p.readOnly)
                        );
                        form.querySelectorAll("[name],button").forEach(
                            p => (p.disabled = !p.disabled)
                        );
                    });
            } else {

                let tmpSubmit = document.createElement("button");
                form.appendChild(tmpSubmit);
                tmpSubmit.click();
                form.removeChild(tmpSubmit);
            }
        });
    });






    $("button.404CallbackFormSubmission").on("click", async function(event) {

        event.preventDefault();

        grecaptcha.ready(async() => {


            let form = event.target.closest("form");

            const eventName = form.getAttribute("id").toSnake();
            let dataPressPhone = form.querySelector("input[data-press-phone]");
            let dataPressName = form.querySelector("input[data-press-name]");
            let entity_type = form.querySelector("input[entity-type]");
            let full_url = form.querySelector("input[current-url]");



            if (
                dataPressName.value.length < 2
            ) {
                Toast.fire({
                    icon: "warning",
                    title: "Please provide a valid name!"
                });
                dataPressName.focus();
                dataPressName.classList.add("is-invalid");
                return false;
            } else {
                dataPressName.classList.remove("is-invalid");
            }

            let phone_dial = '';
            if(dataPressPhone.hasAttribute("dial")) {
                phone_dial = dataPressPhone.getAttribute("dial").trim();
            }

            if (phone_dial == '+91' && dataPressPhone.value.length != 10) {
                Toast.fire({
                    icon: "warning",
                    title: "Please provide a valid number!"
                });
                dataPressPhone.focus();
                dataPressPhone.classList.add("is-invalid");
                return false;
            }else {
                dataPressPhone.classList.remove("is-invalid");
            }

            // if (
            //     dataPressPhone.value.length < 10 ||
            //     dataPressPhone.value.length > 14 ||
            //     dataPressPhone.value.charAt(0) === '0'
            // ) {
            //     Toast.fire({
            //         icon: "warning",
            //         title: "Please provide a valid number!"
            //     });
            //     dataPressPhone.focus();
            //     dataPressPhone.classList.add("is-invalid");
            //     return false;
            // }


       
            if(dataPressPhone.hasAttribute("dial")) {
                phone_dial = dataPressPhone.getAttribute("dial").trim();
            }

            if (phone_dial == '+91' && dataPressPhone.value.length != 10) {
                Toast.fire({
                    icon: "warning",
                    title: "Please provide a valid number!"
                });
                dataPressPhone.focus();
                dataPressPhone.classList.add("is-invalid");
                return false;
            }else {
                dataPressPhone.classList.remove("is-invalid");
            }


            if (
                dataPressPhone.value.length < 5 ||
                dataPressPhone.value.length > 14 ||
                dataPressPhone.value.charAt(0) === '0'
            ) {
                Toast.fire({
                    icon: "warning",
                    title: "Please provide a valid number!"
                });
                dataPressPhone.focus();
                dataPressPhone.classList.add("is-invalid");
                return false;
            }



            if (form.checkValidity()) {

                form.querySelectorAll("[name],button").forEach(
                    p => (p.readOnly = !p.readOnly)
                );

                if (dataPressPhone.hasAttribute("dial")) {
                    dataPressPhone.value =
                        dataPressPhone.getAttribute("dial").trim() +
                        dataPressPhone.value.trim();
                }

                let fd = new FormData(form);
          

                // const recaptcha = await recaptchaInit(eventName);

                //fd.append("recaptcha", recaptcha);

                form.querySelectorAll("[name],button").forEach(
                    p => (p.disabled = !p.disabled)
                );

                let urlParams = new URLSearchParams(window.location.href);
                let gclid_data = urlParams.get('gclid');

               
                $.ajaxSetup({
                    headers: {
                        "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr(
                            "content"
                        )
                    }
                });

                $.ajax({
                        url: form.getAttribute("action"),
                        type: "post",
                        data: fd,
                        cache: false,
                        contentType: false,
                        processData: false
                    })
                    .done(function(response) {

                        if (response.status == "success") {
                            $(form).html(
                                "<div class='second-opinion-submitted pb-1 color-gray-600'>" +
                                " Thank you for submitting your details. One of our health experts will reach out to you shortly." +
                                "</div>"
                            );
                            $(form).prev().hide();

                        } else if (response.status === "error") {
                            showErrors(response.errors);

                            resetForm(form, dataPressPhone);
                        } else {
                            $(form).append(
                                "<div class='color-white text-center f-18'>" +
                                "Submission failed. Please try after sometime." +
                                "</div>"
                            );
                            $(form).prev().hide();

                            console.error(response);
                        }
                    })
                    .fail(function(error) {
                        console.error("error", error);
                        form.querySelectorAll("[name],button").forEach(
                            p => (p.readOnly = !p.readOnly)
                        );
                        form.querySelectorAll("[name],button").forEach(
                            p => (p.disabled = !p.disabled)
                        );
                    });
            } else {

                let tmpSubmit = document.createElement("button");
                form.appendChild(tmpSubmit);
                tmpSubmit.click();
                form.removeChild(tmpSubmit);
            }
        });
    });


    $("button#second-opinion-submit").on("click", async function(e) {
        e.preventDefault();

        grecaptcha.ready(async() => {
            let form = e.target.closest("form#second-opinion");
            let dataPressPhone = form.querySelector("input[data-press-phone]");
            let dataPressEmail = form.querySelector("input[data-press-email]");
            // if (!(dataPressEmail.value.length > 0)) {
            //     Toast.fire({
            //         icon: "warning",
            //         title: "Please provide a valid email!"
            //     });
            //     dataPressEmail.focus();
            //     dataPressEmail.classList.add("is-invalid");
            //     return false;
            // }

            let phone_dial = '';
            if(dataPressPhone.hasAttribute("dial")) {
                phone_dial = dataPressPhone.getAttribute("dial").trim();
            }

            if (phone_dial == '+91' && dataPressPhone.value.length != 10) {
                Toast.fire({
                    icon: "warning",
                    title: "Please provide a valid number!"
                });
                dataPressPhone.focus();
                dataPressPhone.classList.add("is-invalid");
                return false;
            }else {
                dataPressPhone.classList.remove("is-invalid");
            }

            if (
                dataPressPhone.value.length < 5 ||
                dataPressPhone.value.length > 14||
                dataPressPhone.value.charAt(0) === '0'
            ) {
                Toast.fire({
                    icon: "warning",
                    title: "Please provide a valid number!"
                });
                dataPressPhone.focus();
                dataPressPhone.classList.add("is-invalid");
                return false;
            }

            if (form.checkValidity()) {
                form.querySelectorAll("[name],button").forEach(
                    p => (p.readOnly = !p.readOnly)
                );
                if (dataPressPhone.hasAttribute("dial")) {
                    dataPressPhone.value =
                        dataPressPhone.getAttribute("dial").trim() +
                        dataPressPhone.value.trim();
                }
                let fd = new FormData($("form#second-opinion").get(0));
                fd.append("submission-url", window.location.href);
                fd.append(
                    "_token",
                    form.querySelector("input[name='_token']").value
                );

                const recaptcha = await recaptchaInit("second_opinion");

                fd.append("recaptcha", recaptcha);

                form.querySelectorAll("[name],button").forEach(
                    p => (p.disabled = !p.disabled)
                );
                $.ajaxSetup({
                    headers: {
                        "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr(
                            "content"
                        )
                    }
                });
                $.ajax({
                        url: form.getAttribute("action"),
                        type: "post",
                        data: fd,
                        cache: false,
                        contentType: false,
                        processData: false
                    })
                    .done(function(response) {
                        if (response.status == "success") {
                            $("form#second-opinion").html(
                                "<div class='second-opinion-page-submitted'>" +
                                "Thank you for the submission. We will get back to you soon." +
                                "</div>"
                            );

                            $("form#second-opinion")
                                .parent()
                                .addClass("secondOpinionMsg");
                            document
                                .querySelector(".announcement-section-panel")
                                .classList.add("secondOpinionHeight");

                            dataLayer.push({
                                'event': 'Mhc_second_opinion_page_form_submit'
                            });

                        } else if (response.status === "error") {
                            const errors = response.errors;

                            const errorsTargetEl = $(
                                "#second-opinion-page-alerts"
                            );

                            Object.keys(errors).forEach(ix => {
                                const entry = errors[ix][0];

                                errorsTargetEl.append(`

                                    <div class="alert-card">
                                        <div class="alert warning-alert">
                                            <i class="info fa fa-exclamation-circle"></i>
                                            <h3>${entry}</h3>
                                            <i class="close fa fa-times"
                                                onclick="((e) => {e.parentElement.parentElement.style.opacity = 0;setTimeout(() => {e.parentElement.parentElement.remove();}, 250);})(this);"></i>
                                        </div>
                                    </div>
                                `);

                                if (dataPressPhone.hasAttribute("dial")) {
                                    dataPressPhone.value = dataPressPhone.value
                                        .trim()
                                        .replace(
                                            dataPressPhone
                                            .getAttribute("dial")
                                            .trim(),
                                            ""
                                        );
                                }

                                form.querySelectorAll("[name],button").forEach(
                                    p => (p.readOnly = !p.readOnly)
                                );

                                form.querySelectorAll("[name],button").forEach(
                                    p => (p.disabled = !p.disabled)
                                );
                            });
                        } else {
                            $("form#second-opinion").append(
                                "<div class='text-center f-18'>" +
                                "Submission failed. Please try after sometime." +
                                "</div>"
                            );
                        }
                    })
                    .fail(function(error) {
                        console.log("error", error);
                        form.querySelectorAll("[name],button").forEach(
                            p => (p.disabled = !p.disabled)
                        );
                    });
            } else {
                var tmpSubmit = document.createElement("button");
                form.appendChild(tmpSubmit);
                tmpSubmit.click();
                form.removeChild(tmpSubmit);
            }
        });
    });

    $("button.intRoboticSubmit").on("click", async function(event) {

        event.preventDefault();

        //grecaptcha.ready(async() => {


        let form = event.target.closest("form");

        const eventName = form.getAttribute("id").toSnake();

        let dataPressName = form.querySelector("input[data-press-name]");


        let dataPressPhone = form.querySelector("input[data-press-phone]");
        let dataPressEmail = form.querySelector("input[data-press-email");
        let dataPressCountry = form.querySelector("input[data-press-country");

        // console.log("dataPressPhone---",dataPressPhone,"===========", dataPressCountry.value);
        // alert(1);
        // return
        if (
        dataPressName.value.length < 2
        ) {
        Toast.fire({
            icon: "warning",
            title: "Please provide a valid name!"
        });
        dataPressName.focus();
        dataPressName.classList.add("is-invalid");
        return false;
        } else {
        dataPressName.classList.remove("is-invalid");
        }


        let phone_dial = '';
        if(dataPressPhone.hasAttribute("dial")) {
            phone_dial = dataPressPhone.getAttribute("dial").trim();
        }

        if (phone_dial == '+91' && dataPressPhone.value.length != 10) {
            Toast.fire({
                icon: "warning",
                title: "Please provide a valid number!"
            });
            dataPressPhone.focus();
            dataPressPhone.classList.add("is-invalid");
            return false;
        }else {
            dataPressPhone.classList.remove("is-invalid");
        }


        if (
        dataPressPhone.value.length < 5 ||
        dataPressPhone.value.length > 14 ||
        dataPressPhone.value.charAt(0) === '0'
        ) {
        Toast.fire({
            icon: "warning",
            title: "Please provide a valid number!"
        });
        dataPressPhone.focus();
        dataPressPhone.classList.add("is-invalid");
        return false;
        }else {
            dataPressPhone.classList.remove("is-invalid");
        }





        // let validRegex = '[a-z0-9]+@[a-z]+\.[a-z]{2,3}';
        // if(dataPressEmail.value){
        //     if (!dataPressEmail.value.match(validRegex)) {
        //         Toast.fire({
        //             icon: "warning",
        //             title: "Please provide a valid email!"
        //         });
        //         try {
        //             dataPressEmail.focus();
        //         } catch (e) {}
        //         dataPressEmail.classList.add("is-invalid");
        //         return false;
        //     }else {
        //         dataPressEmail.classList.remove("is-invalid");
        //     }
        // }



            if (
                dataPressCountry.value.length < 2
                ) {
                Toast.fire({
                    icon: "warning",
                    title: "Please select country!"
                });
                    dataPressCountry.focus();
                    dataPressCountry.classList.add("is-invalid");
                    return false;
                } else {
                    dataPressCountry.classList.remove("is-invalid");
            }





        if (form.checkValidity()) {

        form.querySelectorAll("[name],button").forEach(
            p => (p.readOnly = !p.readOnly)
        );

        if (dataPressPhone.hasAttribute("dial")) {
            dataPressPhone.value =
                dataPressPhone.getAttribute("dial").trim() +
                dataPressPhone.value.trim();
        }



        //if (entity_type.value == 'Doctor Second Opinion') {



        let fd = new FormData(form);
        // const recaptcha = await recaptchaInit(eventName);

        //fd.append("recaptcha", recaptcha);

        // console.log("getclevertapId",clever.getclevertapId());

        clevertap.event.push("international_form", {
            "Name": dataPressName.value,
            "Phone": dataPressPhone.value,
            "Email": dataPressEmail.value,
            "Country": dataPressCountry.value,
            "URL": window.location.href
        });



        clevertap.onUserLogin.push({
            "Site": {
                "Phone": dataPressPhone.value,
                "Email": dataPressEmail.value,
                "Name": dataPressName.value
            }
        });




        if (window.location.href.indexOf("/en/doctor/") > -1) {
            clevertap.event.push("Intl-Doctor-profile", {
                "URL": window.location.href,
                "Doctor Name": $(".capture_head").text(),
                "Form Submitted":true
            });
        }



        if (window.location.href.indexOf("/international/en/contact-us") > -1) {
            clevertap.event.push("Intl-Contact-us", {
                "Referrer Page URL": document.referrer,
                "Form Submitted":true
            });
        }


        if (window.location.href.indexOf("/international/en/doctors") > -1) {
            clevertap.event.push("Intl-Doctor-Page", {
                "URL": window.location.href,
                "Form Submitted":true
            });
        }

        if (window.location.href.indexOf("/international/en/speciality/") > -1) {
            clevertap.event.push("Intl-Specialities", {
                "Speciality Page":$(".capture_head").text(),
                "URL": window.location.href,
                "Form Submitted":true
            });
        }




        if (window.location.href.indexOf("/international/en/procedure/") > -1) {
            clevertap.event.push("Intl-Procedures", {
                "Procedure Page":$(".capture_head").text(),
                "URL": window.location.href,
                "Form Submitted":true
            });
        }

        if (window.location.href.indexOf("/international/en/cost/") > -1) {
            clevertap.event.push("Intl-Costs", {
                "Treatment Name":$(".capture_head").text(),
                "Additional Members": $("#additional_member").val()-1,
                "Food Cost": $("#food_cost").val(),
                "Hotel Cost": $("#hotel_cost").val(),
                "URL": window.location.href,
                "Form Submitted":true
            });
        }





        form.querySelectorAll("[name],button").forEach(
            p => (p.disabled = !p.disabled)
        );

        let full_url = window.location.href;
        fd.append("full_url", full_url);

        $.ajaxSetup({
            headers: {
                "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr(
                    "content"
                )
            }
        });

        $(form).prev().hide();
        $(form).html(
            "<div id='oaderContainer' class='loader-container'>" +
            "<div id='loader' class='loader'>" + "</div>" +
            "<span>"+ "Loading..." +"</span>" +
            "</div>"

        );

        $.ajax({
                url: form.getAttribute("action"),
                type: "post",
                data: fd,
                cache: false,
                contentType: false,
                processData: false
            })
            .done(function(response) {

                if (response.status == "success") {
                    $(form).html(
                        "<div class='second-opinion-submitted pb-1 color-gray-600'>" +
                        " Thank you for submitting your details. One of our health experts will reach out to you shortly." +
                        "</div>"
                    );
                    $(form).prev().hide();

                } else if (response.status === "error") {
                    showErrors(response.errors);

                    resetForm(form, dataPressPhone);
                } else {
                    $(form).append(
                        "<div class='color-white text-center f-18'>" +
                        "Submission failed. Please try after sometime." +
                        "</div>"
                    );
                    $(form).prev().hide();

                    console.error(response);
                }
            })
            .fail(function(error) {
                console.error("error", error);
                form.querySelectorAll("[name],button").forEach(
                    p => (p.readOnly = !p.readOnly)
                );
                form.querySelectorAll("[name],button").forEach(
                    p => (p.disabled = !p.disabled)
                );
            });
        } else {

        let tmpSubmit = document.createElement("button");
        form.appendChild(tmpSubmit);
        tmpSubmit.click();
        form.removeChild(tmpSubmit);
        }
        //});
    });

    const $submitOpinionBlogFormSidebarMobile = $('#submitOpinionBlogFormSidebarNewBlogMobile');
    const $agreeNewBlogMobile = $('#agreeNewBlogMobile');
    $submitOpinionBlogFormSidebar.prop('disabled', !$agreeNewBlog.is(':checked'));
    $submitOpinionBlogFormSidebarMobile.prop('disabled', !$agreeNewBlogMobile.is(':checked'));

    $agreeNewBlog.change(function() {
        $submitOpinionBlogFormSidebar.prop('disabled', !$(this).is(':checked'));
            if ($(this).is(':checked')) {
                $error_msg_blog_check.hide();
            } else {
                $error_msg_blog_check.show();
            }
    });

    $agreeNewBlogMobile.change(function() {
        $submitOpinionBlogFormSidebarMobile.prop('disabled', !$(this).is(':checked'));
            if ($(this).is(':checked')) {
                $error_msg_blog_check.hide();
            } else {
                $error_msg_blog_check.show();
            }
    });


    const $unitButton = $('#unitFormSubmission1');
    const $errorMsg = $('.error-msg');
    const $unitcheckBox = $('#agreeCheckbox');

    const $unitButton2 = $('#unitFormSubmission2');
    const $errorMsg2 = $('.error-msg2');
    const $unitcheckBox2 = $('#agreeCheckbox2');

    const $consultBtn = $('#book-doctor-link');
    const $errorMsgConsultBtn = $('.error-msg-consult');
    const $consultCheckBox = $('#consultCheckBox');


  
    const $consultCheckBoxMobile = $('#consultCheckBoxMobile');
    

    $unitButton.prop('disabled', !$unitcheckBox.is(':checked'));
    $unitButton2.prop('disabled', !$unitcheckBox2.is(':checked'));
    $consultBtn.prop('disabled', !$consultCheckBox.is(':checked'));
    $consultBtn.prop('disabled', !$consultCheckBoxMobile.is(':checked'));

    $unitcheckBox.change(function() {
    $unitButton.prop('disabled', !$(this).is(':checked'));
        if ($(this).is(':checked')) {
            $errorMsg.hide();
        } else {
            $errorMsg.show();
        }
    });

    $consultCheckBox.change(function() {
        $consultBtn.prop('disabled', !$(this).is(':checked'));
            if ($(this).is(':checked')) {
                $errorMsgConsultBtn.hide();
            } else {
                $errorMsgConsultBtn.show();
            }
    });


    $consultCheckBoxMobile.change(function() {
        $consultBtn.prop('disabled', !$(this).is(':checked'));
            if ($(this).is(':checked')) {
                $errorMsgConsultBtn.hide();
            } else {
                $errorMsgConsultBtn.show();
            }
    });


    $unitcheckBox2.change(function() {
        $unitButton2.prop('disabled', !$(this).is(':checked'));
            if ($(this).is(':checked')) {
                $errorMsg2.hide();
            } else {
                $errorMsg2.show();
            }
    });

    $("button.unitFormSubmission1").on("click", async function(event) {
        event.preventDefault();

        grecaptcha.ready(async() => {
                let form = event.target.closest("form");
                const eventName = form.getAttribute("id").toSnake();
                let dataPressPhone = form.querySelector("input[data-press-phone]");
                let dataPressName = form.querySelector("input[data-press-name]");
                //let dataPressEmail = form.querySelector("input[data-press-email]");
                let entity_type = form.querySelector("input[entity-type]");


                let dataPressCountry = form.querySelector("input[data-press-country");



                if (
                    dataPressPhone.value.length < 5 ||
                    dataPressPhone.value.length > 14 ||
                    dataPressPhone.value.charAt(0) === '0'
                ) {
                    Toast.fire({
                        icon: "warning",
                        title: "Please provide a valid number!"
                    });
                    dataPressPhone.focus();
                    dataPressPhone.classList.add("is-invalid");
                    return false;
                } else {
                    dataPressPhone.classList.contains("is-invalid") ?
                        dataPressPhone.classList.remove("is-invalid") :
                        "";
                }

                if (
                    dataPressCountry.value == 'India (भारत)' &&
                    dataPressPhone.value.length != 10
                    ) {
                    Toast.fire({
                        icon: "warning",
                        title: "Please provide a valid number!"
                    });
                    dataPressPhone.focus();
                    dataPressPhone.classList.add("is-invalid");
                    return false;
                    }else {
                        dataPressPhone.classList.remove("is-invalid");
                }



                if (
                    dataPressName.value.length < 2
                ) {
                    Toast.fire({
                        icon: "warning",
                        title: "Please provide a valid name!"
                    });
                    dataPressName.focus();
                    dataPressName.classList.add("is-invalid");
                    return false;
                } else {
                    dataPressName.classList.remove("is-invalid");
                }


                /*
                                if (!(dataPressEmail.value.length > 0)) {
                                    Toast.fire({
                                        icon: "warning",
                                        title: "Please provide a valid email!"
                                    });
                                    try {
                                        dataPressEmail.focus();
                                    } catch (e) {}
                                    dataPressEmail.classList.add("is-invalid");
                                    return false;
                                } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(dataPressEmail.value)) {

                                    let checkOut = !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(dataPressEmail.value);
                                    console.log("chekcouttttt", checkOut);
                                    Toast.fire({
                                        icon: "warning",
                                        title: "Please provide a valid email!"
                                    });
                                    dataPressEmail.focus();
                                    dataPressEmail.classList.add("is-invalid");
                                    return false;
                                } else {
                                    dataPressEmail.classList.remove("is-invalid");
                                }

                */



                if (form.checkValidity()) {

                    form.querySelectorAll("[name],button").forEach(
                        p => (p.readOnly = !p.readOnly)
                    );

                    if (dataPressPhone.hasAttribute("dial")) {
                        dataPressPhone.value =
                            // dataPressPhone.getAttribute("dial").trim() +
                            dataPressPhone.value.trim();
                    }



                    if (entity_type.value == 'Doctor Second Opinion') {
                        dataLayer.push({
                            'event': 'cps_so_form_banner_racb'
                        });
                    }

                    if (entity_type.value == 'Doctor Unit Page') {
                        dataLayer.push({
                            'event': 'cps_hos_form_banner_racb'
                        });

                    }




                    let fd = new FormData(form);

                    fd.delete("mobile");
                    fd.append("mobile",dataPressPhone.getAttribute("dial").trim()+dataPressPhone.value);

                    // const recaptcha = await recaptchaInit(eventName);

                    // fd.append("recaptcha", recaptcha);

                    form.querySelectorAll("[name],button").forEach(
                        p => (p.disabled = !p.disabled)
                    );

                    $.ajaxSetup({
                        headers: {
                            "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr(
                                "content"
                            )
                        }
                    });

                    $(form).prev().hide();
                    $(form).html(
                        "<div id='oaderContainer' class='loader-container'>" +
                        "<div id='loader' class='loader'>" + "</div>" +
                        "<span>"+ "Loading..." +"</span>" +
                        "</div>"

                    );

                    $.ajax({
                            url: form.getAttribute("action"),
                            type: "post",
                            data: fd,
                            cache: false,
                            contentType: false,
                            processData: false
                        })
                        .done(function(response) {

                            if (response.status == "success") {
                                $(form).html(
                                    "<div class='second-opinion-submitted pb-1 color-gray-600'>" +
                                    " Thank you for submitting your details. One of our health experts will reach out to you shortly." +
                                    "</div>"
                                );
                                $(form).prev().hide();

                            } else if (response.status === "error") {
                                showErrors(response.errors);

                                resetForm(form, dataPressPhone);
                            } else {
                                $(form).append(
                                    "<div class='color-white text-center f-18'>" +
                                    "Submission failed. Please try after sometime." +
                                    "</div>"
                                );
                                $(form).prev().hide();

                                console.error(response);
                            }
                        })
                        .fail(function(error) {
                            console.error("error", error);
                            form.querySelectorAll("[name],button").forEach(
                                p => (p.readOnly = !p.readOnly)
                            );
                            form.querySelectorAll("[name],button").forEach(
                                p => (p.disabled = !p.disabled)
                            );
                        });
                } else {

                    let tmpSubmit = document.createElement("button");
                    form.appendChild(tmpSubmit);
                    tmpSubmit.click();
                    form.removeChild(tmpSubmit);
                }
            }

        );
    });



    $("button.unitFormSubmissionCommon").on("click", async function(event) {

        event.preventDefault();

        grecaptcha.ready(async() => {
                let form = event.target.closest("form");
                const eventName = form.getAttribute("id").toSnake();
                let dataPressPhone = form.querySelector("input[data-press-phone]");
                let dataPressName = form.querySelector("input[data-press-name]");
                let dataPressEmail = form.querySelector("input[data-press-email]");
                let dataPressCountry = form.querySelector("input[data-press-country");
                let dataPressConsultation = form.querySelector("select[consultaion-select-box");
                let dataPressHospital = form.querySelector("select[hospital-select-box");




                if (
                    dataPressConsultation.value == null || dataPressConsultation.value == ''
                ) {
                    Toast.fire({
                        icon: "warning",
                        title: "Please select consultation type!"
                    });
                    dataPressConsultation.focus();
                    dataPressConsultation.classList.add("is-invalid");
                    return false;
                } else {
                    dataPressConsultation.classList.contains("is-invalid") ?
                        dataPressConsultation.classList.remove("is-invalid") :
                        "";
                }

                if (
                      dataPressHospital.value == null || dataPressHospital.value == ''
                ) {
                    Toast.fire({
                        icon: "warning",
                        title: "Please select hospital!"
                    });
                    dataPressHospital.focus();
                    dataPressHospital.classList.add("is-invalid");
                    return false;
                } else {
                    dataPressHospital.classList.contains("is-invalid") ?
                        dataPressHospital.classList.remove("is-invalid") :
                        "";
                }


                let phone_dial = '';
                if(dataPressPhone.hasAttribute("dial")) {
                    phone_dial = dataPressPhone.getAttribute("dial").trim();
                }
    
                if (phone_dial == '+91' && dataPressPhone.value.length != 10) {
                    Toast.fire({
                        icon: "warning",
                        title: "Please provide a valid number!"
                    });
                    dataPressPhone.focus();
                    dataPressPhone.classList.add("is-invalid");
                    return false;
                }else {
                    dataPressPhone.classList.remove("is-invalid");
                }

                if (
                    dataPressPhone.value.length < 5 ||
                    dataPressPhone.value.length > 14 ||
                    dataPressPhone.value.charAt(0) === '0'
                ) {
                    Toast.fire({
                        icon: "warning",
                        title: "Please provide a valid number!"
                    });
                    dataPressPhone.focus();
                    dataPressPhone.classList.add("is-invalid");
                    return false;
                } else {
                    dataPressPhone.classList.contains("is-invalid") ?
                        dataPressPhone.classList.remove("is-invalid") :
                        "";
                }

            

                if (
                    dataPressName.value.length < 2
                ) {
                    Toast.fire({
                        icon: "warning",
                        title: "Please provide a valid name!"
                    });
                    dataPressName.focus();
                    dataPressName.classList.add("is-invalid");
                    return false;
                } else {
                    dataPressName.classList.remove("is-invalid");
                }


                // if(!(dataPressEmail.value.length > 0) || (dataPressEmail.value.length == 0)) {
                //     Toast.fire({
                //         icon: "warning",
                //         title: "Please provide a valid email!"
                //     });
                //     try {
                //         dataPressEmail.focus();
                //     } catch (e) {}
                //     dataPressEmail.classList.add("is-invalid");
                //     return false;
                // } else


            if(dataPressEmail.value.length > 0){
                if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(dataPressEmail.value)) {

                    let checkOut = !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(dataPressEmail.value);
                    console.log("chekcouttttt", checkOut);
                    Toast.fire({
                        icon: "warning",
                        title: "Please provide a valid email!"
                    });
                    dataPressEmail.focus();
                    dataPressEmail.classList.add("is-invalid");
                    return false;
                } else {
                    dataPressEmail.classList.remove("is-invalid");
                }
            }else{
                dataPressEmail.classList.remove("is-invalid");
            }


                if (form.checkValidity()) {

                    form.querySelectorAll("[name],button").forEach(
                        p => (p.readOnly = !p.readOnly)
                    );

                    if (dataPressPhone.hasAttribute("dial")) {
                        dataPressPhone.value =
                            // dataPressPhone.getAttribute("dial").trim() +
                            dataPressPhone.value.trim();
                    }



                    let fd = new FormData(form);

                    fd.delete("mobile");
                    fd.append("mobile",dataPressPhone.getAttribute("dial").trim()+dataPressPhone.value);

                    // const recaptcha = await recaptchaInit(eventName);

                    // fd.append("recaptcha", recaptcha);

                    form.querySelectorAll("[name],button").forEach(
                        p => (p.disabled = !p.disabled)
                    );

                    $.ajaxSetup({
                        headers: {
                            "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr(
                                "content"
                            )
                        }
                    });

                    $(form).prev().hide();
                    $(form).html(
                        "<div id='oaderContainer' class='loader-container'>" +
                        "<div id='loader' class='loader'>" + "</div>" +
                        "<span>"+ "Loading..." +"</span>" +
                        "</div>"

                    );

                    $.ajax({
                            url: form.getAttribute("action"),
                            type: "post",
                            data: fd,
                            cache: false,
                            contentType: false,
                            processData: false
                        })
                        .done(function(response) {

                            if (response.status == "success") {
                                $(form).html(
                                    "<div class='second-opinion-submitted pb-1 color-gray-600'>" +
                                    " Thank you for submitting your details. One of our health experts will reach out to you shortly." +
                                    "</div>"
                                );
                                $(form).prev().hide();

                            } else if (response.status === "error") {
                                showErrors(response.errors);

                                resetForm(form, dataPressPhone);
                            } else {
                                $(form).append(
                                    "<div class='color-white text-center f-18'>" +
                                    "Submission failed. Please try after sometime." +
                                    "</div>"
                                );
                                $(form).prev().hide();

                                console.error(response);
                            }
                        })
                        .fail(function(error) {
                            console.error("error", error);
                            form.querySelectorAll("[name],button").forEach(
                                p => (p.readOnly = !p.readOnly)
                            );
                            form.querySelectorAll("[name],button").forEach(
                                p => (p.disabled = !p.disabled)
                            );
                        });
                } else {

                    let tmpSubmit = document.createElement("button");
                    form.appendChild(tmpSubmit);
                    tmpSubmit.click();
                    form.removeChild(tmpSubmit);
                }
            }

        );
    });


    $("button.intcallBackSubmission").on("click", async function(event) {

        event.preventDefault();

        //grecaptcha.ready(async() => {


        let form = event.target.closest("form");

        const eventName = form.getAttribute("id").toSnake();

        let dataPressName = form.querySelector("input[data-press-name]");


        let dataPressPhone = form.querySelector("input[data-press-phone]");
        let dataPressEmail = form.querySelector("input[data-press-email");
        let dataPressCountry = form.querySelector("input[data-press-country");


        if (
        dataPressName.value.length < 2
        ) {
        Toast.fire({
            icon: "warning",
            title: "Please provide a valid name!"
        });
        dataPressName.focus();
        dataPressName.classList.add("is-invalid");
        return false;
        } else {
        dataPressName.classList.remove("is-invalid");
        }


        let phone_dial = '';
                if (dataPressPhone.hasAttribute("dial")) {
                    phone_dial =
                        dataPressPhone.getAttribute("dial").trim();
                }



        if (
                ( (dataPressPhone.value.length < 8 || dataPressPhone.value.length > 14) && phone_dial != '+91')||
                (dataPressPhone.value.length !=10 && phone_dial == '+91') ||
                dataPressPhone.value.charAt(0) === '0'
                ) {
                Toast.fire({
                    icon: "warning",
                    title: "Please provide a valid number!"
                });
                dataPressPhone.focus();
                dataPressPhone.classList.add("is-invalid");
                return false;
                }else {
                    dataPressPhone.classList.remove("is-invalid");
                }


        // if (!(dataPressEmail.value.length > 0)) {
        //         Toast.fire({
        //             icon: "warning",
        //             title: "Please provide a valid email!"
        //         });
        //         try {
        //             dataPressEmail.focus();
        //         } catch (e) {}
        //         dataPressEmail.classList.add("is-invalid");
        //         return false;
        //     }


        let validRegex = '[a-z0-9]+@[a-z]+\.[a-z]{2,3}';

            if (!dataPressEmail.value.match(validRegex)) {
                Toast.fire({
                    icon: "warning",
                    title: "Please provide a valid email!"
                });
                try {
                    dataPressEmail.focus();
                } catch (e) {}
                dataPressEmail.classList.add("is-invalid");
                return false;
            }else {
                dataPressEmail.classList.remove("is-invalid");
            }



            if (
                dataPressCountry.value.length < 2
                ) {
                Toast.fire({
                    icon: "warning",
                    title: "Please select country!"
                });
                    dataPressCountry.focus();
                    dataPressCountry.classList.add("is-invalid");
                    return false;
                } else {
                    dataPressCountry.classList.remove("is-invalid");
            }





        if (form.checkValidity()) {

        form.querySelectorAll("[name],button").forEach(
            p => (p.readOnly = !p.readOnly)
        );

        if (dataPressPhone.hasAttribute("dial")) {
            dataPressPhone.value =
                dataPressPhone.getAttribute("dial").trim() +
                dataPressPhone.value.trim();
        }



        //if (entity_type.value == 'Doctor Second Opinion') {



        let fd = new FormData(form);
        // const recaptcha = await recaptchaInit(eventName);

        //fd.append("recaptcha", recaptcha);

        // console.log("getclevertapId",clever.getclevertapId());

        clevertap.event.push("international_form", {
            "Name": dataPressName.value,
            "Phone": dataPressPhone.value,
            "Email": dataPressEmail.value,
            "Country": dataPressCountry.value,
            "URL": window.location.href
        });


        clevertap.onUserLogin.push({
            "Site": {
                "Phone": dataPressPhone.value,
                "Email": dataPressEmail.value,
                "Name": dataPressName.value
            }
        });






        if (window.location.href.indexOf("/en/doctor/") > -1) {
            clevertap.event.push("Intl-Doctor-profile", {
                "URL": window.location.href,
                "Doctor Name": $(".capture_head").text(),
                "Form Submitted":true
            });
        }



        if (window.location.href.indexOf("/international/en/contact-us") > -1) {
            clevertap.event.push("Intl-Contact-us", {
                "Referrer Page URL": document.referrer,
                "Form Submitted":true
            });
        }


        if (window.location.href.indexOf("/international/en/doctors") > -1) {
            clevertap.event.push("Intl-Doctor-Page", {
                "URL": window.location.href,
                "Form Submitted":true
            });
        }

        if (window.location.href.indexOf("/international/en/speciality/") > -1) {
            clevertap.event.push("Intl-Specialities", {
                "Speciality Page":$(".capture_head").text(),
                "URL": window.location.href,
                "Form Submitted":true
            });
        }




        if (window.location.href.indexOf("/international/en/procedure/") > -1) {
            clevertap.event.push("Intl-Procedures", {
                "Procedure Page":$(".capture_head").text(),
                "URL": window.location.href,
                "Form Submitted":true
            });
        }

        if (window.location.href.indexOf("/international/en/cost/") > -1) {
            clevertap.event.push("Intl-Costs", {
                "Treatment Name":$(".capture_head").text(),
                "Additional Members": $("#additional_member").val()-1,
                "Food Cost": $("#food_cost").val(),
                "Hotel Cost": $("#hotel_cost").val(),
                "URL": window.location.href,
                "Form Submitted":true
            });
        }




        form.querySelectorAll("[name],button").forEach(
            p => (p.disabled = !p.disabled)
        );

        const recaptcha = await recaptchaInit(eventName);

        fd.append("recaptcha", recaptcha);

        let full_url = window.location.href;
        fd.append("full_url", full_url);

        $.ajaxSetup({
            headers: {
                "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr(
                    "content"
                )
            }
        });

        $.ajax({
                url: form.getAttribute("action"),
                type: "post",
                data: fd,
                cache: false,
                contentType: false,
                processData: false
            })
            .done(function(response) {

                if (response.status == "success") {
                    $(form).html(
                        "<div class='second-opinion-submitted pb-1 color-gray-600'>" +
                        " Thank you for submitting your details. One of our health experts will reach out to you shortly." +
                        "</div>"
                    );
                    $(form).prev().hide();

                } else if (response.status === "error") {
                    showErrors(response.errors);

                    resetForm(form, dataPressPhone);
                } else {
                    $(form).append(
                        "<div class='color-white text-center f-18'>" +
                        "Submission failed. Please try after sometime." +
                        "</div>"
                    );
                    $(form).prev().hide();

                    console.error(response);
                }
            })
            .fail(function(error) {
                console.error("error", error);
                form.querySelectorAll("[name],button").forEach(
                    p => (p.readOnly = !p.readOnly)
                );
                form.querySelectorAll("[name],button").forEach(
                    p => (p.disabled = !p.disabled)
                );
            });
        } else {

        let tmpSubmit = document.createElement("button");
        form.appendChild(tmpSubmit);
        tmpSubmit.click();
        form.removeChild(tmpSubmit);
        }
        //});
    });


});
