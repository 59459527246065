export const recaptchaInit = async(page) => {

    const action = (page || 'home') + '_page';

    const token = await grecaptcha.execute('6LfsbusoAAAAAC2ap-cKDeUcAsoj-IFkk7Q0c4y9', {

        action: action

    })

    .then((token) => {

        if (token) {

            return token;
        }

        return false;

    });

    return token;
};
